<template>
    <base-modal close @modal-close="closeModal" :show="show" class="w-[440px]">
        <template #modal-header>
            <span class="text-lg font-medium text-gray-900">
                Unreconcile transaction
            </span>
        </template>

        <p class="pb-2 text-sm font-medium text-gray-500">
            The statement lines matched with these transactions will be
            unreconciled.
        </p>

        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width @click="onConfirm">
                    Yes, unreconcile
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'modal-confirm'])

defineProps({
    show: {
        type: Boolean,
        default: true,
    },
})

const closeModal = () => {
    emit('modal-close')
}

const onConfirm = () => {
    emit('modal-confirm')
}
</script>
