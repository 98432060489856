<template>
    <infinite-data-grid
        table-borderless
        :data-source="entities || []"
        :columns="columns"
        :loading="loading"
        identify-field="id"
        @load-more="onLoadMore"
        custom-classes="border-t"
    >
        <template #column-vendor="{ item }">
            <div class="text-sm font-normal text-gray-500">
                {{ getContactName(item.contact) }}
            </div>
        </template>

        <template #column-date="{ item }">
            <div class="text-sm font-normal text-gray-500">
                {{ $filters.dateHumanFormat(item.date) }}
            </div>
        </template>

        <template #column-amount="{ item }">
            {{ $filters.currency(item.unpaid_amount, item.currency.iso) }}
        </template>

        <template #empty v-if="$slots['empty']">
            <slot name="empty" />
        </template>
    </infinite-data-grid>
</template>
<script setup>
import { getContactName } from '@tenant/utils/helper'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { uniqBy } from 'lodash-es'
import { TRANSACTION_TYPE } from '@tenant/utils/constants'

const props = defineProps({
    queries: {
        type: Object,
        default: () => ({}),
    },
    compact: {
        type: Boolean,
        default: false,
    },
    transaction: {
        type: Object,
        required: true,
    },
})

const { useApiSearch: useApiSearchSpent } = useApiFactory(
    'transactions/spent-records'
)
const { useApiSearch: useApiSearchReceived } = useApiFactory(
    'transactions/received-records'
)

const { execute: executeGetSpent } = useApiSearchSpent({}, false)
const { execute: executeGetReceived } = useApiSearchReceived({}, false)

const { t } = useI18n()

const columns = [
    {
        property: 'date',
        label: t('purchasesExpense.list.columns.date'),
    },
    {
        property: 'vendor',
        label: t('purchasesExpense.list.columns.vendorName'),
    },
    {
        property: 'reference',
        label: t('purchasesExpense.list.columns.referenceNumber'),
    },
    {
        property: 'amount',
        label: t('purchasesExpense.list.columns.amount'),
    },
]

const onLoadMore = () => {
    if (hasMore.value) {
        fetchMore()
    }
}

const route = useRoute()
const {
    data: entities,
    isFetching: loading,
    fetchNextPage: fetchMore,
    hasNextPage: hasMore,
} = useInfiniteQuery({
    queryKey: ['bills-expenses', props.transaction.id],
    queryFn: ({ pageParam = 1 }) => {
        return props.transaction.transaction_type === TRANSACTION_TYPE.INCOME
            ? executeGetReceived({
                  currency_id: props.transaction.currency_id,
                  limit: 6,
                  ...route.query,
                  cursor: pageParam,
              })
            : executeGetSpent({
                  currency_id: props.transaction.currency_id,
                  limit: 6,
                  ...route.query,
                  cursor: pageParam,
              })
    },
    getNextPageParam: ({ next_cursor }) => next_cursor,
    select: (res) =>
        uniqBy(
            res?.pages?.flatMap((x) => x?.data),
            (x) => x?.id
        ),
})
</script>
