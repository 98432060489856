<template>
    <div
        class="flex items-start gap-3 border-b border-warning-300 bg-warning-50 p-4 text-warning-700"
    >
        <base-icon
            name="line-icons:alerts-&-feedback:alert-triangle"
            variant="inherit"
        />
        <div class="flex flex-col items-start gap-3 text-sm font-medium">
            <p>
                {{ $t('bookeepingBank.yourConnectionExpired') }}
            </p>
            <div
                class="flex cursor-pointer items-center gap-2"
                @click="onReconnect"
            >
                {{ $t('bookeepingBank.connectNow') }}
                <base-icon
                    name="line-icons:arrows:arrow-right"
                    variant="inherit"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
import { useBankConnect } from '@tenant/modules/tenant/bookeeping-bank/compositions/use-bank-connect'

const props = defineProps({
    paymentAccount: {
        type: Object,
        required: true,
    },
})

const { t } = useI18n()
const emitter = useEmitter()
const { errorNotify } = useNotification()

const { reconnectBank } = useBankConnect()

const onReconnect = async () => {
    try {
        emitter.emit('set-loading', true)
        await reconnectBank(props.paymentAccount.id)
    } catch {
        errorNotify({
            text: t(
                'settingsAccounting.paymentAccount.connectBank.cannotConnectMsg'
            ),
        })
        emitter.emit('set-loading', false)
    }
}
</script>
