<template>
    <data-grid
        :selectable="paymentAccount.type === ACCOUNT_TYPES.BANK_FEED"
        select-classes="border-r"
        :data-source="entities.data"
        :columns="columns"
        :loading="loadingSearch"
        identify-field="id"
        :table-borderless="true"
        :empty-text="$t('bookeepingBank.emptyText')"
        :empty-sub="$t('bookeepingBank.emptySubText')"
        @on-items-selected="onItemsSelected"
    >
        <template #outer-header>
            <template v-if="paymentAccount.type === ACCOUNT_TYPES.BANK_FEED">
                <div
                    v-if="$acl.can('update_bank_accounts')"
                    class="flex gap-2.5 border-b border-t border-b-gray-300 border-t-gray-200 px-6 py-3.5"
                >
                    <base-button
                        variant="default"
                        :disabled="state.selectedItems.length === 0"
                        size="sm"
                        @click="onRestore"
                    >
                        {{ $t('general.restore') }}
                    </base-button>
                    <base-button
                        variant="default"
                        :disabled="state.selectedItems.length === 0"
                        size="sm"
                        @click="onDelete"
                    >
                        {{ $t('general.delete') }}
                    </base-button>
                </div>
            </template>
        </template>

        <template #column-description="{ item }">
            {{ item.memo || item.description }}
        </template>

        <template #column-dateOfTransfer="{ item }">
            {{ $filters.dateHumanFormat(item?.date_of_transfer) }}
        </template>

        <template #column-type="{ item }">
            <span>
                {{
                    $t(
                        `bookeepingBank.statement.transactionType.${item.transaction_type}`
                    )
                }}
            </span>
        </template>

        <template #column-status="{ item }">
            <span v-if="item.deleted_at">
                <base-badge :label="$t('general.deleted')" variant="danger" />
            </span>
            <span v-else-if="item.booked_transactions_count">
                <base-badge
                    :label="$t('bookeepingBank.reconciled')"
                    variant="success"
                />
            </span>
            <span v-else>
                <base-badge
                    :label="$t('bookeepingBank.unreconciled')"
                    variant="warning"
                />
            </span>
        </template>

        <template #column-spent="{ item }">
            <span class="whitespace-nowrap text-danger-600">
                {{
                    item.amount < 0
                        ? $filters.currency(item.amount, item.currency?.iso)
                        : ''
                }}
            </span>
        </template>

        <template #column-received="{ item }">
            <span class="whitespace-nowrap text-success-600">
                {{
                    item.amount >= 0
                        ? $filters.currency(item.amount, item.currency?.iso)
                        : ''
                }}
            </span>
        </template>

        <template #column-source>
            {{ $t('bookeepingBank.statement.source.bankFeed') }}
        </template>

        <template #footer>
            <grid-pagination
                :total="entities.last_page"
                :page="entities.current_page"
            />
        </template>
    </data-grid>
    <bookeeping-bank-detail-statement-delete-modal
        :show="state.showDeleteConfirm"
        :selected-items="state.selectedItems"
        @modal-close="closeDeleteConfirm"
        @on-finish="onFinishDelete"
    />
</template>
<script setup>
import { ACCOUNT_TYPES } from '@tenant/utils/constants'

const { t } = useI18n()
const route = useRoute()
const emitter = useEmitter()

const props = defineProps({
    routeProps: {
        type: Object,
        required: true,
    },
})

useHead({
    title: t('bookeepingBank.title'),
})

const paymentAccount = computed(() => props.routeProps)

const { invalidatePaymentAccount } = usePaymentAccount()
const { entities, useApiSearch } = useApiFactory('transactions')
const { loading: loadingSearch } = useApiSearch({
    payment_account_id: route.params.id,
    trashed: true,
})

const { execute: restoreTransactions } = useApi(
    '/api/transactions/restore',
    'POST'
)

const columns = [
    {
        property: 'dateOfTransfer',
        label: t('bookeepingBank.statement.columns.dateOfTransfer'),
        headerCellClass: 'w-36',
    },
    {
        property: 'name',
        label: t('bookeepingBank.statement.columns.payee'),
    },
    {
        property: 'description',
        label: t('bookeepingBank.statement.columns.description'),
    },
    {
        property: 'spent',
        label: t('bookeepingBank.statement.columns.spent'),
    },
    {
        property: 'received',
        label: t('bookeepingBank.statement.columns.received'),
    },
    {
        property: 'status',
        label: t('bookeepingBank.statement.columns.status'),
    },
]

const state = reactive({
    selectedItems: [],
    showDeleteConfirm: false,
})

const onItemsSelected = (items) => {
    state.selectedItems = items
}

const onDelete = () => {
    state.showDeleteConfirm = true
}

const closeDeleteConfirm = () => {
    state.showDeleteConfirm = false
}

const onFinishDelete = () => {
    closeDeleteConfirm()
    reloadBankDetail()
}

const reloadBankDetail = () => {
    emitter.emit('set-reload')
    invalidatePaymentAccount()
}

const onRestore = () => {
    emitter.emit('set-loading', true)

    restoreTransactions({
        data: {
            ids: state.selectedItems.map((i) => i.id),
        },
    })
        .then(() => {
            reloadBankDetail()
        })
        .finally(() => {
            emitter.emit('set-loading', false)
        })
}
</script>
