<template>
    <div class="pb-16">
        <h5 class="mb-2.5 text-xl font-semibold text-gray-900">
            {{ $t('bookeepingBank.import.prepareFileToUpload') }}
        </h5>
        <p class="mb-10 text-md font-normal text-gray-900">
            {{ $t('bookeepingBank.import.downloadBankStatement') }}
        </p>
        <p class="mb-2.5 text-md font-semibold text-gray-900">
            {{ $t('bookeepingBank.import.fileToUpload') }}
        </p>

        <form-file-upload
            v-model="value"
            :max-files="1"
            :description="$t('bookeepingBank.import.fileShouldEnd')"
            :accepted-files="['.csv']"
            :error-message="errorMessage"
        />
    </div>
</template>
<script setup>
import jsonToFormData from 'json-form-data'
import { useSetFieldValue, useSetFormErrors } from 'vee-validate'

const emit = defineEmits(['on-change-state'])

const emitter = useEmitter()
const emitterAsync = useEmitterAsync()

const { execute } = useApi('/api/payment-accounts/import/preview', 'POST')

const { value, errorMessage, setValue } = useField('document')
const setErrors = useSetFormErrors()
const setFileData = useSetFieldValue('file_data')
const setAssignColumns = useSetFieldValue('assign_columns')

onMounted(() => {
    setValue(null)
    emitterAsync.on('on-next', onNext)
})

onUnmounted(() => {
    emitterAsync.off('on-next', onNext)
})

watch(
    () => value.value,
    () => {
        changeState()
    }
)

const changeState = () => {
    emit('on-change-state', { disabledNext: !value.value })
}

const onNext = async () => {
    try {
        emitter.emit('set-loading', true)

        const data = jsonToFormData({
            document: value.value,
        })

        const { file_data, assign_columns } = await execute({
            headers: { 'Content-Type': 'multipart/form-data' },
            data,
        })

        setFileData(file_data)
        setAssignColumns(assign_columns)
    } catch ({ errors }) {
        setErrors(errors)

        throw errors
    } finally {
        emitter.emit('set-loading', false)
    }
}
</script>
