<template>
    <div
        v-if="matched"
        class="flex h-40 w-full flex-1 bg-success-100 text-md font-normal text-gray-900"
    >
        <div
            class="flex flex-1 gap-2.5 overflow-x-auto border border-success-300 px-6 py-2.5"
            style="height: 160px"
        >
            <base-icon
                class="text-gray-600"
                name="line-icons:files:file-06"
                variant="inherit"
            />
            <div class="flex flex-1 flex-col overflow-x-auto">
                <time class="text-sm text-gray-600">
                    {{ $filters.dateHumanFormat(getDateDocument(match)) }}
                </time>
                <span>
                    {{
                        $t('bookeepingBank.document_for.' + type, {
                            amount: $filters.symbolCurrency(
                                getAmount(match),
                                transaction.currency
                            ),
                        })
                    }}
                </span>
                <span class="truncate">
                    {{
                        $t('bookeepingBank.ref', {
                            number: getDocumentNumber(match),
                        })
                    }}
                </span>
            </div>
        </div>

        <div
            class="flex w-32 flex-col items-end gap-0.5 border border-l border-l-0 border-gray-300 border-success-300 px-6 py-2.5"
        >
            <span class="text-xs text-gray-600">
                {{ $t('bookeepingBank.reconcile.spent') }}
            </span>
            <span v-if="!isIncome">
                {{ $filters.numberFormat(getAmount(match)) }}
            </span>
        </div>

        <div
            class="flex w-32 flex-col items-end gap-0.5 border border-l border-l-0 border-gray-300 border-success-300 px-6 py-2.5"
        >
            <span class="text-xs text-gray-600">
                {{ $t('bookeepingBank.reconcile.received') }}
            </span>
            <span v-if="isIncome">
                {{ $filters.numberFormat(getAmount(match)) }}
            </span>
        </div>
    </div>

    <div
        v-else
        class="flex h-40 flex-col justify-center border border-gray-300 bg-gray-100 px-6 text-center"
    >
        <p class="text-lg font-semibold text-gray-700">
            {{ $t('bookeepingBank.reconcile.findAndMatch') }}
        </p>
    </div>
</template>

<script setup>
import {
    MATCHING_TRANSACTION_TYPES,
    TRANSACTION_TYPE,
} from '@tenant/utils/constants'

const emit = defineEmits(['find-match'])

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})
const match = computed(() => props.transaction.match_item)
const matched = computed(() =>
    [
        MATCHING_TRANSACTION_TYPES.BILL,
        MATCHING_TRANSACTION_TYPES.EXPENSE,
    ].includes(match.value?.match_type)
)

watch(
    () => match.value,
    (value) => {
        if (value) {
            emit('find-match', value)
        }
    },
    { immediate: true }
)

const isIncome = computed(() => {
    return props.transaction.transaction_type === TRANSACTION_TYPE.INCOME
})

const type = computed(() => {
    switch (props.transaction.transaction_type) {
        case TRANSACTION_TYPE.INCOME:
            return 'income'
        case TRANSACTION_TYPE.EXPENSE:
            return 'document_number' in match.value ? 'outgoing' : 'expense'
    }

    return null
})

const getAmount = (match) => {
    return isIncome.value
        ? match.sale_document.unpaid_amount
        : match.unpaid_amount ?? match.amount_gross_total
}

const getDocumentNumber = (match) => {
    return isIncome.value
        ? match.invoice_header
        : match.document_number ?? match.reference
}

const getDateDocument = (match) => {
    return isIncome.value
        ? match.date_of_invoice
        : match.date_of_document ?? match.date
}
</script>
