export default {
    bookeeping: {
        title: 'Bookeeping',
    },
    bookeepingBank: {
        title: 'Bank Accounts',
        noBankAccount: 'No bank account',
        noBankAccountHint: 'Currently you have no bank accounts.',
        addBankAccount: 'Add Bank Account',
        emptyText: 'No transactions found',
        emptySubText: 'Create your first transaction',
        refreshTransaction:
            'Refresh has been initiated and may take a while to complete. You can continue working until then.',
        taxRate: 'Tax Rate',
        who: 'Who',
        what: 'What',
        why: 'Why',
        ref: 'Ref: {number}',
        for: 'for {name}',
        addTransaction: 'Add Transaction',
        spendMoney: 'Spend Money',
        receiveMoney: 'Receive Money',
        reconciled: 'Reconciled',
        unreconciled: 'Unreconciled',
        connectNow: 'Connect now',
        yourConnectionExpired:
            'Your connection with GoCardless has expired. Connect to GoCardless again to continue fetching your bank feeds.',

        manualModal: {
            title: 'New Transaction',
            recordAManual: 'Record a manual transaction for your account.',
            spendMoney: 'Spend Money',
            receivedMoney: 'Received Money',
            youSpendMoney: 'You spend money for an expense',
            youReceiveMoney: 'You received money as income',
            account: 'Account',
            paymentAccount: 'Payment account',
            transactionDate: 'Transaction date',
            amount: 'Amount',
            tax: 'Tax',
            taxPlaceholder: 'Select a tax rate',
            taxInclusive: 'Tax inclusive',
            customer: 'Customer',
            customerPlaceholder: 'Select customer',
            reference: 'Reference',
            description: 'Description',
            descriptionPlaceholder: 'Enter a description...',
            descriptionHint:
                'Write a description that help you to identify the transaction later.',
            createSuccess: 'Transaction created successfully',
            uploadDescription: 'SVG, PNG, JPG or GIF (max. 800x400px)',
        },

        feedHistory: {
            booked_transaction: {
                reconciled: 'Reconciled the transaction.',
            },
        },

        document_for: {
            incoming: 'Invoice for {amount}',
            outgoing: 'Bill for {amount}',
            expense: 'Expense for {amount}',
            transaction: 'Transaction matches {amount}',
        },

        tabs: {
            reconcile: 'Reconcile',
            bankStatements: 'Bank statements',
            accountTransactions: 'Account transactions',
        },

        reconcile: {
            searchPlaceholder:
                'Search for Payee, Amount, Reference, Description, Transaction Type',
            reviewYourBank: 'Review your bank statement lines ...',
            baseOnActivity:
                'Based on your activity or search query there are no statement lines to show.',
            thenMatch: '...then match with your transactions in Cybooks',
            moreDetail: 'More Details',
            options: 'Options',
            createRule: 'Create Rule',
            spent: 'Spent',
            received: 'Received',
            findAndMatch: 'Find & select matching transactions',

            reconciledView: {
                title: 'Great Job!',
                description:
                    'You’ve reconciled all transactions for this account.',
                statementBalance: 'Statement Balance',
            },

            match: {
                tabs: {
                    match: 'Match',
                    create: 'Create',
                    transfer: 'Transfer',
                    discuss: 'Discuss',
                },

                nameOfTheContact: 'Name of the contact',
                selectAnAccount: 'Select an account',
                enterADescription: 'Enter a description',
                notFound: {
                    title: 'No Transactions found',
                    description:
                        'There are no transactions matching the selected criteria.',
                },
            },
            transfer: {
                bankAccountLabel: 'Select bank account',
                referenceLabel: 'Reference',
                referencePlaceholder: 'Enter the reference number',
            },
            cybooksSuggest: 'Cybooks suggest',
            askClientQuestion: 'Ask Client question',
            transactionDetails: {
                title: 'Statement Details',
                transactionDate: 'Transaction Date',
                description: 'Description',
                transactionType: 'Transaction Type',
                amount: 'Transaction amount',
                reference: 'Reference',
                payee: 'Payee',
                currency: 'Currency',
            },
            create: {
                applyRule: 'Apply Rule',
                contactName: 'Contact Name',
                editRule: 'Edit Rule',
                doNotApplyRule: 'Do not apply rule',
            },
        },
        bankCard: {
            statementBalance: 'Statement Balance',
            balanceInCybooks: 'Balance in Cybooks',
            reconcileBtn: 'Reconcile 1 item | Reconcile {count} items',
            noTransactionsText: 'No transactions imported',
            manageAccount: 'Manage account',
            createManualTransaction: 'Create manual transaction',
            accountTransactions: 'Account Transactions',
            bankStatements: 'Bank Statements',
            importBankStatement: 'Import bank statement',
        },
        statement: {
            columns: {
                dateOfTransfer: 'Date of transfer',
                type: 'Type',
                payee: 'Payee',
                description: 'Description',
                reference: 'Reference',
                spent: 'Spent',
                received: 'Received',
                balance: 'Balance',
                source: 'Source',
                status: 'Status',
                locked: 'Locked',
            },
            lockedDescription:
                'Locked transactions can’t be edited anymore since they were already posted. Often through a VAT return. Click here to learn more.',
            transactionType: {
                incoming: 'DEBIT',
                outgoing: 'CREDIT',
            },
            source: {
                bankFeed: 'Bank Feed',
            },
            deleteStatementLine: 'Delete Statement Line',
            areYouSureWantToDeleteStatement:
                'Are you sure you want to delete this statement line? This will change your statement balance in Cybooks.',
            generallyThisIsOnly:
                'Generally this is only required to remove duplicate statement lines.',
            ifThisIsAPersonalExpense:
                'If this is a personal expense, deleting the statement line is usually not advised',
        },
        matchTransactions: {
            step1: '1. Find & select matching transactions',
            step2: '2. The sum of your selected transactions must match the money spent. Make adjustments, as needed.',
            totalsMatch: 'Totals match',
            totalsOut: 'Total is out by: ',
            adjustmentsLabel: 'Adjustments',
            adjustments: {
                bankFee: 'Bank fee',
                minor: 'Minor adjustment',
            },
            bankFeeColumns: {
                contact: 'Contact',
                description: 'Description',
                amount: 'Amount',
                taxRate: 'Tax rate',
                account: 'Account',
            },
            summary: {
                subtotal: 'Subtotal',
                minorAdjustment: 'Minor adjustment',
                matchAlert: 'Must match money spent {amount}',
                bankFees: {
                    title: 'Bank fees',
                },
            },
            buttons: {
                reconcile: 'Reconcile',
            },
        },
        accountTransactions: {
            slideOut: {
                expense: 'Expense',
                income: 'Income',
                spendTitle: 'Transaction: Spend Money',
                receiveTitle: 'Transaction: Receive Money',
                transactionDetailsTab: 'Transaction Details',
                commentsHistoryTab: 'Comments & History',
                unreconcileBtn: 'Unreconcile',
                exchangeRate: 'Exchange Rate',
                accountName: 'Account Name',
                bankCharges: 'Bank Charges (if any)',
                taxAmount: 'Tax Amount',
                receivedVia: 'Received Via',
                customer: 'Customer',
                supplier: 'Supplier Name',
            },
        },

        import: {
            title: 'Import bank transactions',
            prepareFileToUpload: 'Prepare file for upload',
            downloadBankStatement:
                'Download bank statements from your online banking as an CSV file.',
            fileToUpload: 'File to upload',
            fileShouldEnd: 'File should end in CSV',
            setUpTransactionForImport: 'Set up transactions for import',
            mapAndAssignColumns: 'Map and assign columns',
            assignColumnHeading:
                'Assign column headings in your file to fields in Cybooks, to set how your transactions look. You only need to do this once, but you can edit them when you next import a CSV file.',
            columnHeading: 'Column heading',
            dontImportTheFirstLine:
                'Don’t import the first line because they’re column headings',
            columnHeadingFrom: 'Column heading from CSV file',
            column: 'Column {number}',
            previewFrom: 'Preview from CSV file',
            assignToField: 'Assign to field in Cybooks',
            showingStatement: 'Showing statement line {page} of {total}',
            showingTransaction: 'Showing preview transaction {page} of {total}',
            transactionPreview: 'Transaction preview',
            completeToPreview:
                'Complete import settings to preview transactions',
            reviewAndComplete: 'Review and complete import',
            summaryOfTransactions: 'Summary of transactions',
            checkForDuplicates:
                "Cybooks will check for duplicates when you complete the import, and any duplicates won't show for reconciliation",
            errors: 'Errors',
            transactionWontBeImported: "Transactions of €0 won't be imported.",
            importFailed: 'Import failed, please try again',
            thisFieldIsInvalid: 'This field is invalid',
            dateIsInvalid: 'This field is invalid format, suggest: {format}',
            fields: {
                date_of_transfer: 'Date of transfer',
                amount: 'Transaction amount',
                payee: 'Payee',
                description: 'Description',
                reference: 'Reference',
                transaction_type: 'Transaction type',
                account_code: 'Account code',
            },
            transactionsGrid: {
                date: 'Date',
                customerName: 'Customer Name',
                reference: 'Reference',
                spent: 'Spent',
                received: 'Received',
            },
        },
    },
}
