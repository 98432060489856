export const IMPORT_FIELDS = {
    date_of_transfer: 'date_of_transfer',
    amount: 'amount',
    payee: 'payee',
    description: 'description',
    reference: 'reference',
    transaction_type: 'transaction_type',
    account_code: 'account_code',
}

export const OUTCOME_FIELDS = ['out', 'outgoing', 'outcome']
export const INCOME_FIELDS = ['in', 'incoming', 'income']
