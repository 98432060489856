<template>
    <div
        class="absolute top-full z-50 flex w-full flex-col border border-gray-300 border-t-gray-200 bg-white py-6 shadow-xl"
    >
        <p class="px-6 pb-6 text-md font-semibold text-gray-900">
            {{ $t('bookeepingBank.matchTransactions.step1') }}
        </p>

        <bookeeping-bank-bills-expenses-grid
            expanded-row-classes="!bg-success-100"
            @row-click="onRowClick"
            :transaction="transaction"
        />

        <div class="flex justify-between p-6">
            <p class="text-md font-semibold text-gray-900">
                {{ $t('bookeepingBank.matchTransactions.step2') }}
            </p>

            <bookeeping-bank-detail-adjustment-menu
                :show-bank-fee="showBankFee"
                :show-adjustment="showAdjustment"
                @select-adjustment="onSelectAdjustment"
            />
        </div>

        <div class="flex flex-col gap-2.5" v-if="defaultCurrency">
            <div
                v-if="selectedAmount"
                class="flex justify-between border-gray-300 px-6 font-semibold"
            >
                <p class="text-md text-gray-900">
                    {{
                        $t('bookeepingBank.matchTransactions.summary.subtotal')
                    }}
                </p>
                <p class="text-md">
                    {{ $filters.currency(selectedAmount, defaultCurrency.iso) }}
                </p>
            </div>

            <div
                v-if="showAdjustment"
                class="flex items-center justify-between border-t border-gray-300 px-6 pt-2.5"
            >
                <p class="text-sm">
                    {{
                        $t(
                            'bookeepingBank.matchTransactions.summary.minorAdjustment'
                        )
                    }}
                </p>
                <div class="flex items-center gap-6">
                    <form-field-array-error
                        :name="`line_items[${adjustment.key}].amount`"
                        #="{ errorMessage }"
                    >
                        <form-number-input
                            v-model="adjustmentValue"
                            size="sm"
                            :precision="2"
                            :block="false"
                            :error-message="errorMessage"
                        />
                    </form-field-array-error>

                    <base-icon
                        class="cursor-pointer"
                        name="line-icons:general:trash-01"
                        variant="gray"
                        @click="hideAdjustment"
                    />
                </div>
            </div>

            <div v-if="showBankFee" class="flex flex-col py-2.5 font-semibold">
                <p class="mb-2.5 px-6 text-md text-gray-900">
                    {{
                        $t(
                            'bookeepingBank.matchTransactions.summary.bankFees.title'
                        )
                    }}
                </p>

                <bookeeping-bank-detail-adjustment-bank-fee
                    v-if="bankFeeLineItems.length"
                    :data-source="bankFeeLineItems"
                    @amount-change="onBankAmountChange"
                    @remove="hideBankFee"
                />
            </div>

            <div class="flex gap-6 px-6">
                <div
                    class="flex flex-1 justify-between border-t-[3px] border-gray-300 pt-2.5 font-semibold"
                >
                    <p class="text-sm">
                        {{
                            $t(
                                'bookeepingBank.matchTransactions.summary.matchAlert',
                                {
                                    amount: $filters.currency(
                                        Math.abs(transaction.amount),
                                        defaultCurrency.iso
                                    ),
                                }
                            )
                        }}
                    </p>
                    <p class="text-md">
                        {{
                            $filters.currency(totalAmount, defaultCurrency.iso)
                        }}
                    </p>
                </div>

                <div class="flex items-center gap-1.5 text-sm">
                    <template v-if="matched">
                        <base-feature-icon
                            class="!border-none !p-1.5"
                            name="line-icons:general:check"
                            variant="success"
                            size="xs"
                            type="highlight"
                        />
                        <p class="text-success-600">
                            {{
                                $t(
                                    'bookeepingBank.matchTransactions.totalsMatch'
                                )
                            }}
                        </p>
                    </template>

                    <template v-else>
                        <base-feature-icon
                            class="!border-2 !p-1.5"
                            name="line-icons:alerts-&-feedback:alert-circle"
                            variant="danger"
                            size="xs"
                            type="dark"
                        />
                        <p class="text-danger-600">
                            {{
                                $t('bookeepingBank.matchTransactions.totalsOut')
                            }}
                            <span class="font-semibold">{{
                                $filters.currency(
                                    remainingAmount,
                                    defaultCurrency.iso
                                )
                            }}</span>
                        </p>
                    </template>
                </div>
            </div>
        </div>

        <div class="mt-4 flex justify-end gap-3 px-6">
            <base-button
                :disabled="!matched"
                @click="onReconcile"
                :loading="isReconciling"
            >
                {{ $t('bookeepingBank.matchTransactions.buttons.reconcile') }}
            </base-button>
            <base-button variant="default" @click="$emit('close')">
                {{ $t('general.cancel') }}
            </base-button>
        </div>
    </div>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="onCloseBookCloseModal"
    />
</template>
<script setup>
import BigNumber from 'bignumber.js'
import {
    TRANSACTION_ADJUSTMENTS,
    TRANSACTION_TYPE,
} from '@tenant/utils/constants'
import { useQueryClient } from '@tanstack/vue-query'

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})
defineEmits(['close', 'add-adjustment'])

const { defaultCurrency } = useCurrencies()

const showAdjustment = ref(false)
const adjustmentValue = ref(0)
const adjustment = computed(() => {
    return fields.value.find(
        ({ value }) => value.type === TRANSACTION_ADJUSTMENTS.MINOR
    )
})
watch(
    () => adjustmentValue.value,
    (newVal) => {
        if (!newVal) return

        const { key, value } = unref(adjustment)
        update(key, {
            ...value,
            amount: newVal,
        })
    }
)

const hideAdjustment = () => {
    showAdjustment.value = false
    adjustmentValue.value = null
    const index = fields.value.findIndex(
        ({ value }) => value.type === TRANSACTION_ADJUSTMENTS.MINOR
    )
    remove(index)
}

const onSelectAdjustment = (value) => {
    if (value === TRANSACTION_ADJUSTMENTS.MINOR) {
        push({
            type: value,
            contact_id: null,
            contact: null,
            notes: null,
            chart_of_account_id: null,
            tax_rule_id: null,
            amount: null,
        })
        showAdjustment.value = true
        return
    }

    if (value === TRANSACTION_ADJUSTMENTS.BANK_FEE) {
        if (bankFeeLineItems.value.length === 0) {
            push({
                type: value,
                contact_id: null,
                contact: null,
                notes: null,
                chart_of_account_id: null,
                tax_rule_id: null,
                amount: null,
            })
        }
        showBankFee.value = true
    }
}

// BANK FEE
const showBankFee = ref(false)
const hideBankFee = () => {
    const index = fields.value.findIndex(
        ({ value }) => value.type === TRANSACTION_ADJUSTMENTS.BANK_FEE
    )
    remove(index)
    showBankFee.value = false
    bankFeeValue.value = 0
}

const outgoingType = computed(() => {
    if (props.transaction.transaction_type === TRANSACTION_TYPE.INCOME) {
        return ''
    } else {
        return selectedTransaction.value?.type
    }
})

const { handleSubmit, setErrors } = useForm()
const { push, fields, remove, update } = useFieldArray('line_items')

const bankFeeLineItems = computed(() => {
    return fields.value.filter(
        ({ value }) => value.type === TRANSACTION_ADJUSTMENTS.BANK_FEE
    )
})

const bankFeeValue = ref(0)
const onBankAmountChange = (value) => {
    bankFeeValue.value = value || 0
}
// END: BANK FEE

const selectedTransaction = ref(null)
const onRowClick = (_, item) => {
    selectedTransaction.value = item
    adjustmentValue.value = 0
}

const selectedAmount = computed(() => {
    return new BigNumber(selectedTransaction.value?.unpaid_amount ?? 0)
        .decimalPlaces(2)
        .toNumber()
})

const matched = computed(() =>
    new BigNumber(props.transaction.amount)
        .abs()
        .eq(Math.abs(bigNum(totalAmount.value)))
)

const bigNum = (num) => BigNumber(num).decimalPlaces(2).toNumber()

const totalAmount = computed(() => {
    return (
        selectedAmount.value +
        bigNum(adjustmentValue.value || 0) +
        bigNum(bankFeeValue.value || 0)
    )
})

const remainingAmount = computed(() => {
    return (
        Math.abs(bigNum(props.transaction.amount)) -
        Math.abs(bigNum(totalAmount.value))
    )
})

const {
    execute: bookOutgoingPayment,
    code: codeOutgoing,
    parameters: parameterOutgoing,
} = useApi('/api/book-outgoing-payment', 'POST')
const {
    execute: bookIncomingPayment,
    code: codeIncoming,
    parameters: parameterIncoming,
} = useApi('/api/book-incoming-payment', 'POST')

const code = computed(() => {
    return codeOutgoing.value || codeIncoming.value
})

const parameters = computed(() => {
    return parameterOutgoing.value || parameterIncoming.value
})

const onCloseBookCloseModal = () => {
    codeOutgoing.value = null
    codeIncoming.value = null

    parameterOutgoing.value = null
    parameterIncoming.value = null
}

const queryClient = useQueryClient()
const isReconciling = ref(false)
const onReconcile = handleSubmit(async (values) => {
    try {
        isReconciling.value = true

        const data = {
            booked_id: selectedTransaction.value.id,
            outgoing_type: outgoingType.value,
            payment_account_transaction_id: props.transaction.id,
            ...values,
        }

        if (props.transaction.transaction_type === TRANSACTION_TYPE.INCOME) {
            await bookIncomingPayment({ data })
        } else {
            await bookOutgoingPayment({ data })
        }

        await Promise.all([
            queryClient.setQueryData(
                ['bank-transactions-reconcile'],
                (data) => ({
                    pages:
                        data?.pages.map((page) => ({
                            ...page,
                            data:
                                (
                                    page?.data?.filter(
                                        (val) => val.id !== props.transaction.id
                                    ) ?? []
                                ).map((val) => ({
                                    ...val,
                                    match_item:
                                        val?.match_item?.id ===
                                        selectedTransaction.value?.id
                                            ? null
                                            : val.match_item,
                                })) ?? [],
                        })) ?? [],
                    pageParams: data?.pageParams,
                })
            ),

            queryClient.setQueryData(
                ['bank-detail-summary', props.transaction.payment_account_id],
                (data) => ({
                    ...data,
                    reconcile: data.reconcile > 0 ? data.reconcile - 1 : 0,
                })
            ),

            queryClient.invalidateQueries({
                queryKey: ['payment-accounts'],
            }),
        ])
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        isReconciling.value = false
    }
})
</script>
