<template>
    <form-control-group class="border-t border-gray-200 px-6 py-5">
        <form-detail-chart-of-account
            v-model="chartOfAccount"
            :input-display="getChartOfAccountName(transaction.chart_of_account)"
            :key="isOutCome ? 'expense' : 'income'"
            :type="isOutCome ? 'expense' : 'income'"
            :include-modal="false"
            :can-create="false"
            :error-message="chartOfAccountError"
            :label="$t('bookeepingBank.manualModal.account')"
            :placeholder="$t('general.selectAccount')"
        />

        <form-text-input
            :disabled="true"
            :label="$t('bookeepingBank.manualModal.paymentAccount')"
            :model-value="paymentAccount.name"
            size="sm"
        />

        <form-contact-select
            v-model="contact"
            :error-message="contactError"
            :label="$t('bookeepingBank.manualModal.customer')"
            :placeholder="$t('bookeepingBank.manualModal.customerPlaceholder')"
            size="sm"
            :input-display="getContactName(contactData)"
            @on-contact-changed="onContactChange"
        />

        <form-date-picker
            v-model="paymentDate"
            :error-message="paymentDateError"
            single
            :label="$t('bookeepingBank.manualModal.transactionDate')"
            size="sm"
        />

        <div class="flex flex-col gap-2">
            <div class="w-full">
                <form-control-label
                    :label="$t('bookeepingBank.manualModal.amount')"
                />

                <div class="combined-input flex">
                    <form-number-input
                        v-model="amount"
                        :error-message="amountError"
                        :precision="2"
                        :hide-message="true"
                        size="sm"
                    />
                    <div
                        class="flex-col rounded-r-lg border border-gray-300 bg-gray-50 px-4 py-2 text-center"
                    >
                        {{ currency.iso }}
                    </div>
                </div>
            </div>

            <div class="flex cursor-pointer">
                <base-exchange-rate
                    v-model="factor"
                    :from-iso="currency.iso"
                    :to-iso="defaultCurrency?.iso"
                    :exchange-rate="exchangeRate"
                    @on-save-factor="onSaveFactor"
                />
            </div>
        </div>

        <form-control-group-row class="gap-x-5">
            <form-single-select
                v-model="taxRule"
                :error-message="taxRuleError"
                :label="$t('bookeepingBank.manualModal.tax')"
                :placeholder="$t('bookeepingBank.manualModal.taxPlaceholder')"
                :options="taxRules"
                option-value="id"
                option-label="name"
                size="sm"
            />

            <div class="flex items-start whitespace-nowrap">
                <div class="pt-11">
                    <form-toggle
                        v-model="taxInclusive"
                        :label="$t('bookeepingBank.manualModal.taxInclusive')"
                    />
                </div>
            </div>
        </form-control-group-row>

        <form-text-input
            v-model="reference"
            :error-message="referenceError"
            :label="$t('bookeepingBank.manualModal.reference')"
            size="sm"
        />

        <form-textarea
            v-model="description"
            :error-message="descriptionError"
            :label="$t('bookeepingBank.manualModal.description')"
            :placeholder="
                $t('bookeepingBank.manualModal.descriptionPlaceholder')
            "
            :hint-text="$t('bookeepingBank.manualModal.descriptionHint')"
            rows="6"
        />
    </form-control-group>

    <hr />

    <form-control-group class="px-6 pb-1 pt-5">
        <form-file-upload
            v-model="document"
            :max-files="1"
            :description="$t('bookeepingBank.manualModal.uploadDescription')"
            :accepted-files="['.pdf', '.png', '.jpg', '.jpeg']"
            :error-message="documentError"
            :custom-path="customFilePath"
            :custom-name="customFileName"
        />

        <div class="flex items-center justify-end gap-3">
            <base-button
                variant="default"
                size="sm"
                :disabled="state.loading"
                @click="emit('on-cancel')"
            >
                {{ $t('general.cancel') }}
            </base-button>
            <base-button
                variant="primary"
                size="sm"
                :loading="state.loading"
                @click="onSave"
            >
                {{ $t('general.save') }}
            </base-button>
        </div>
    </form-control-group>
</template>
<script setup>
import { AMOUNT_TYPE } from '@tenant/utils/constants'
import { getChartOfAccountName, getContactName } from '@tenant/utils/helper'
import { shortServerFormat } from '@tenant/utils/day'
import BigNumber from 'bignumber.js'

const emit = defineEmits(['on-cancel'])
const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})

const state = reactive({
    loading: false,
})

const emitter = useEmitter()
const { defaultCurrency } = useCurrencies()
const { uploadStorage } = useUploadStorage()
const { successNotify } = useNotification()
const { t } = useI18n()
const { execute } = useApi('/api/book-manual-transaction/{id}', 'PUT')

const isOutCome = computed(() => props.transaction.type === 'outcome')
const paymentAccount = computed(() => props.transaction.payment_account)
const currency = computed(() => paymentAccount.value.currency)

const { handleSubmit, setErrors, setValues } = useForm()

const { value: chartOfAccount, errorMessage: chartOfAccountError } = useField(
    'chart_of_account_id'
)
const { value: exchangeRate } = useField('exchange_rate')
const {
    value: amount,
    errorMessage: amountError,
    setValue: setAmount,
} = useField('amount')
const { value: taxRule, errorMessage: taxRuleError } = useField('tax_rule_id')
const { value: taxType, setValue: setTaxType } = useField('tax_type')
const { value: contact, errorMessage: contactError } = useField('contact_id')
const { value: contactData } = useField('contact')
const { value: paymentDate, errorMessage: paymentDateError } =
    useField('payment_date')
const { value: reference, errorMessage: referenceError } = useField('reference')
const { value: description, errorMessage: descriptionError } =
    useField('description')
const { value: document, errorMessage: documentError } = useField('document')

const taxInclusive = computed({
    get: () => {
        return taxType.value === AMOUNT_TYPE.GROSS
    },
    set: (value) => {
        setTaxType(value ? AMOUNT_TYPE.GROSS : AMOUNT_TYPE.NET)
    },
})

const taxFilter = computed(() => [
    isOutCome.value ? 'purchases' : 'sales',
    'no_vat',
])

const { taxRules } = useTaxRules(taxFilter)

onMounted(() => {
    const { transaction } = props
    const { rate, rate_updated_at } = currency.value

    setValues({
        chart_of_account_id: transaction.chart_of_account_id,
        contact: transaction.contact,
        amount: transaction.amount,
        tax_rule_id: transaction.tax_rule_id,
        contact_id: transaction.contact_id,
        tax_type: transaction.tax_type,
        payment_date: shortServerFormat(transaction.payment_date),
        reference: transaction.journal_entry?.reference,
        description: transaction.notes,
        document: transaction.document,
        exchange_rate: {
            rate,
            date: shortServerFormat(rate_updated_at),
        },
    })
})

const factor = computed({
    get() {
        if (!exchangeRate.value?.rate) {
            return 1
        }

        return new BigNumber(1 / exchangeRate.value?.rate)
            .decimalPlaces(6)
            .toNumber()
    },
    set(newValue) {
        exchangeRate.value.rate = 1 / newValue
    },
})

const onSaveFactor = () => {
    setAmount(amount.value * exchangeRate.value.rate)
}

const onContactChange = ({ id, item }) => {
    contact.value = id
    contactData.value = item
}

const customFileName = (file) => {
    return file.full_name
}

const customFilePath = (file) => {
    return file.preview?.path
}

const onSave = handleSubmit(async ({ document, ...values }) => {
    try {
        state.loading = true
        let uploadKey = null
        if (document) {
            uploadKey = await uploadStorage(document)
        }

        await execute({
            params: {
                id: props.transaction.id,
            },
            data: {
                ...values,
                document: uploadKey,
            },
        })
        successNotify({
            title: t('general.success'),
            text: t('bookeepingBank.manualModal.createSuccess'),
        })

        emitter.emit('set-reload')
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        state.loading = false
    }
})
</script>
