<template>
    <div class="flex flex-col gap-6">
        <p class="text-lg font-semibold text-gray-900">
            {{ $t('bookeepingBank.import.transactionPreview') }}
        </p>

        <template v-if="isValidSettings">
            <div
                class="flex rounded-md border border-gray-300"
                v-if="transaction"
            >
                <div class="flex flex-1 flex-col gap-1 px-6 py-2.5 font-normal">
                    <p class="h-5 text-sm text-gray-600">
                        <template v-if="transaction.date_of_transfer">
                            {{
                                $filters.dateHumanFormat(
                                    transaction.date_of_transfer
                                )
                            }}
                        </template>
                    </p>
                    <p class="h-6 text-md text-gray-900">
                        <template v-if="transaction.description">
                            {{ transaction.description }}
                        </template>
                    </p>
                    <p class="h-6 text-md text-gray-900">
                        <template v-if="transaction.reference">
                            {{ transaction.reference }}
                        </template>
                    </p>
                </div>
                <div
                    class="flex flex-1 flex-col items-end gap-1 border-l border-r border-gray-300 px-6 py-2.5 font-normal"
                >
                    <span class="text-xs text-gray-600">
                        {{ $t('general.spent') }}
                    </span>
                    <span
                        class="text-md text-gray-900"
                        v-if="
                            validTransactionType(
                                transaction.transaction_type,
                                OUTCOME_FIELDS
                            )
                        "
                    >
                        <template v-if="transaction.transaction_type">
                            {{
                                $filters.numberFormat(
                                    Math.abs(transaction.amount)
                                )
                            }}
                        </template>
                        <template v-else-if="transaction.amount < 0">
                            {{
                                $filters.numberFormat(
                                    Math.abs(transaction.amount)
                                )
                            }}
                        </template>
                    </span>
                </div>
                <div
                    class="flex flex-1 flex-col items-end gap-1 px-6 py-2.5 font-normal"
                >
                    <span class="text-xs text-gray-600">
                        {{ $t('general.received') }}
                    </span>
                    <span
                        class="text-md text-gray-900"
                        v-if="
                            validTransactionType(
                                transaction.transaction_type,
                                INCOME_FIELDS
                            )
                        "
                    >
                        <template v-if="transaction.transaction_type">
                            {{
                                $filters.numberFormat(
                                    Math.abs(transaction.amount)
                                )
                            }}
                        </template>
                        <template v-else-if="transaction.amount >= 0">
                            {{
                                $filters.numberFormat(
                                    Math.abs(transaction.amount)
                                )
                            }}
                        </template>
                    </span>
                </div>
            </div>

            <div
                class="flex items-center justify-between rounded-md border border-gray-300 p-3 px-6"
            >
                <span class="text-sm font-medium text-gray-700">
                    {{
                        $t('bookeepingBank.import.showingTransaction', {
                            page: state.page,
                            total: totalRows,
                        })
                    }}
                </span>
                <div class="flex gap-3">
                    <base-button
                        variant="default"
                        size="sm"
                        @click="onPrevious"
                    >
                        {{ $t('general.previous') }}
                    </base-button>
                    <base-button variant="default" size="sm" @click="onNext">
                        {{ $t('general.next') }}
                    </base-button>
                </div>
            </div>
        </template>

        <div
            v-else
            class="flex items-center justify-center rounded-md border border-gray-300 bg-gray-200 px-6 py-10 text-md font-medium text-gray-900"
        >
            {{ $t('bookeepingBank.import.completeToPreview') }}
        </div>
    </div>
</template>
<script setup>
import {
    INCOME_FIELDS,
    OUTCOME_FIELDS,
} from '@tenant/modules/tenant/bookeeping-bank/utils/constants'
import { useAssignTransactions } from '@tenant/modules/tenant/bookeeping-bank/compositions/use-import-transactions'

const state = reactive({
    page: 1,
})

const { totalRows, getTransaction, validTransactionType, isValidSettings } =
    useAssignTransactions()

const transaction = computed(() => getTransaction(state.page - 1))

const onPrevious = () => {
    if (state.page === 1) {
        return
    }

    state.page--
}

const onNext = () => {
    if (state.page >= totalRows.value) {
        return
    }

    state.page++
}
</script>
