<template>
    <base-sticky-heading class="items-center py-5">
        <template #title>
            <h5 class="text-lg font-semibold text-gray-900">
                {{ $t('bookeepingBank.import.title') }}
            </h5>
            <span class="text-sm font-normal text-gray-500" v-if="result">
                {{ $t('bookeepingBank.for', { name: result.name }) }}
            </span>
        </template>

        <template #right>
            <div class="flex items-center justify-center gap-2.5">
                <base-button
                    v-if="state.currentStep > 1"
                    size="sm"
                    variant="default"
                    @click="onBack"
                >
                    {{ $t('general.back') }}
                </base-button>
                <base-button
                    v-if="state.currentStep < steps.length"
                    size="sm"
                    variant="primary"
                    :disabled="state.disabledNext"
                    @click="onNext"
                >
                    {{ $t('general.next') }}
                </base-button>
                <base-button
                    v-else
                    size="sm"
                    variant="primary"
                    :disabled="state.disabledNext"
                    @click="onFinish"
                >
                    {{ $t('general.complete') }}
                </base-button>
            </div>
        </template>

        <div class="p-6">
            <bookeeping-bank-import-steps
                class="mb-12"
                :steps="steps"
                :active-step="state.currentStep"
            />

            <router-view @on-change-state="onChangeState" />
        </div>
    </base-sticky-heading>
</template>
<script setup>
import jsonToFormData from 'json-form-data'

const { errorNotify } = useNotification()
const router = useRouter()
const route = useRoute()
const emitter = useEmitter()
const emitterAsync = useEmitterAsync()

const { t } = useI18n()

useHead({
    title: t('bookeepingBank.import.title'),
})

const { execute: executeImport } = useApi(
    '/api/payment-accounts/{id}/import',
    'POST'
)
const { useApiGet } = useApiFactory('payment-accounts')
const { execute: getPaymentAccount, result } = useApiGet(route.params.id)

onMounted(() => {
    emitter.emit('set-loading', true)
    getPaymentAccount().finally(() => {
        emitter.emit('set-loading', false)
    })
})

const { handleSubmit } = useForm({ keepValuesOnUnmount: true })

const steps = [
    {
        label: 'Upload',
        route: 'bookeeping.bank.import.upload',
    },
    {
        label: 'Import Settings',
        route: 'bookeeping.bank.import.settings',
    },
    {
        label: 'Review',
        route: 'bookeeping.bank.import.review',
    },
]

const state = reactive({
    currentStep: 1,
    disabledNext: false,
})

watch(
    () => state.currentStep,
    () => {
        state.disabledNext = false
    }
)

onMounted(() => {
    router.push({
        name: 'bookeeping.bank.import.upload',
    })
})

const onNext = async () => {
    if (state.currentStep >= steps.length) {
        return
    }

    await emitterAsync.emitAsync('on-next')
    state.currentStep++
    goToStep(state.currentStep)
}

const onFinish = handleSubmit(
    async ({ assign_columns, document, skip_heading }) => {
        try {
            emitter.emit('set-loading', true)

            const data = jsonToFormData({
                assign_columns,
                document,
                skip_heading,
            })

            await executeImport({
                data,
                params: {
                    id: route.params.id,
                },
                headers: { 'Content-Type': 'multipart/form-data' },
            })

            await router.push({
                name: 'bookeeping.bank.detail',
                params: {
                    id: route.params.id,
                },
            })
        } catch {
            errorNotify({
                title: t('general.error'),
                text: t('bookeepingBank.import.importFailed'),
            })

            emitter.emit('set-loading', false)
        }
    }
)

const onBack = () => {
    if (state.currentStep <= 1) {
        return
    }

    state.currentStep--
    goToStep(state.currentStep)
}

const goToStep = (stepNumber) => {
    const step = steps.find((i, idx) => idx + 1 === stepNumber)
    if (!step || !step.route) {
        return
    }

    router.push({
        name: step.route,
    })
}

const onChangeState = (updateState) => {
    Object.assign(state, updateState)
}
</script>
