<template>
    <h5 class="mb-2.5 text-xl font-semibold text-gray-900">
        {{ $t('bookeepingBank.import.setUpTransactionForImport') }}
    </h5>
    <p class="mb-2.5 text-md font-semibold text-gray-900">
        {{ $t('bookeepingBank.import.mapAndAssignColumns') }}
    </p>
    <p class="mb-6 text-sm font-normal text-gray-500">
        {{ $t('bookeepingBank.import.assignColumnHeading') }}
    </p>

    <div class="mb-6">
        <form-checkbox
            v-model="skipHeading"
            :text="$t('bookeepingBank.import.columnHeading')"
            :supporting-text="
                $t('bookeepingBank.import.dontImportTheFirstLine')
            "
        />
    </div>

    <bookeping-bank-import-settings-columns class="mb-12" />
    <bookeping-bank-import-settings-transaction />
</template>
<script setup>
import { useAssignTransactions } from '@tenant/modules/tenant/bookeeping-bank/compositions/use-import-transactions'

const emit = defineEmits(['on-change-state'])

const { value: skipHeading } = useField('skip_heading')

const { isValidSettings } = useAssignTransactions()

watch(
    () => isValidSettings.value,
    () => {
        changeState()
    }
)

onMounted(() => {
    changeState()
})

const changeState = () => {
    emit('on-change-state', { disabledNext: !isValidSettings.value })
}
</script>
