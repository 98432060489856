<template>
    <base-modal
        :show="show"
        size="sxl"
        close
        has-separator
        :loading="state.loading"
        @modal-close="closeModal"
    >
        <template #modal-header>
            <div class="mb-5 flex flex-col gap-2">
                <h5 class="text-lg font-medium text-gray-900">
                    {{ $t('bookeepingBank.manualModal.title') }}
                </h5>
                <p class="text-sm font-normal text-gray-500">
                    {{ $t('bookeepingBank.manualModal.recordAManual') }}
                </p>
            </div>

            <div class="grid grid-cols-2 gap-3">
                <form-radio-group-item
                    v-model="type"
                    v-for="bookedType in bookedTypes"
                    :value="bookedType.value"
                    name="type"
                    bullet-position="left"
                    bullet-icon=""
                    :key="bookedType.value"
                >
                    <template #default="{ checked }">
                        <div class="text-sm">
                            <p
                                class="font-medium"
                                :class="
                                    checked
                                        ? 'text-primary-800'
                                        : 'text-gray-700'
                                "
                            >
                                {{ bookedType.label }}
                            </p>
                            <p
                                class=""
                                :class="
                                    checked
                                        ? 'text-primary-600'
                                        : 'text-gray-500'
                                "
                            >
                                {{ bookedType.description }}
                            </p>
                        </div>
                    </template>
                </form-radio-group-item>
            </div>
        </template>

        <template #content>
            <form-control-group class="px-6 py-5">
                <form-control-group-row class="gap-x-5">
                    <form-detail-chart-of-account
                        v-model="chartOfAccount"
                        :key="
                            type === BOOKED_TRANSACTION_TYPE.OUTCOME
                                ? 'expense'
                                : 'income'
                        "
                        :type="
                            type === BOOKED_TRANSACTION_TYPE.OUTCOME
                                ? 'expense'
                                : 'income'
                        "
                        :include-modal="false"
                        :can-create="false"
                        :error-message="chartOfAccountError"
                        :label="$t('bookeepingBank.manualModal.account')"
                        :placeholder="$t('general.selectAccount')"
                    />
                    <form-text-input
                        :disabled="true"
                        :label="$t('bookeepingBank.manualModal.paymentAccount')"
                        :model-value="paymentAccount.name"
                        size="sm"
                    />
                </form-control-group-row>

                <form-control-group-row class="gap-x-5">
                    <form-contact-select
                        v-model="contact"
                        :error-message="contactError"
                        :label="$t('bookeepingBank.manualModal.customer')"
                        :placeholder="
                            $t('bookeepingBank.manualModal.customerPlaceholder')
                        "
                        size="sm"
                        :input-display="getContactName(contactData)"
                        @on-contact-changed="onContactChange"
                    />

                    <form-date-picker
                        v-model="paymentDate"
                        :error-message="paymentDateError"
                        single
                        :label="
                            $t('bookeepingBank.manualModal.transactionDate')
                        "
                        size="sm"
                    />
                </form-control-group-row>
                <div class="flex flex-col">
                    <div class="flex cursor-pointer">
                        <base-exchange-rate
                            v-model="factor"
                            :from-iso="currency.iso"
                            :to-iso="defaultCurrency?.iso"
                            :exchange-rate="exchangeRate"
                            @on-save-factor="onSaveFactor"
                        />
                    </div>

                    <form-control-group-row class="gap-x-5">
                        <div class="w-full">
                            <form-control-label
                                :label="$t('bookeepingBank.manualModal.amount')"
                            />

                            <div class="combined-input flex">
                                <form-number-input
                                    v-model="amount"
                                    :error-message="amountError"
                                    :precision="2"
                                    :hide-message="true"
                                    size="sm"
                                />
                                <div
                                    class="flex-col rounded-r-lg border border-gray-300 bg-gray-50 px-4 py-2 text-center"
                                >
                                    {{ currency.iso }}
                                </div>
                            </div>
                        </div>

                        <form-single-select
                            v-model="taxRule"
                            :error-message="taxRuleError"
                            :label="$t('bookeepingBank.manualModal.tax')"
                            :placeholder="
                                $t('bookeepingBank.manualModal.taxPlaceholder')
                            "
                            :options="taxRules"
                            option-value="id"
                            option-label="name"
                            size="sm"
                        />

                        <div class="flex w-full items-start">
                            <div class="pt-11">
                                <form-toggle
                                    v-model="taxInclusive"
                                    :label="
                                        $t(
                                            'bookeepingBank.manualModal.taxInclusive'
                                        )
                                    "
                                />
                            </div>
                        </div>
                    </form-control-group-row>
                </div>

                <form-text-input
                    v-model="reference"
                    :error-message="referenceError"
                    :label="$t('bookeepingBank.manualModal.reference')"
                    size="sm"
                />

                <form-textarea
                    v-model="description"
                    :error-message="descriptionError"
                    :label="$t('bookeepingBank.manualModal.description')"
                    :placeholder="
                        $t('bookeepingBank.manualModal.descriptionPlaceholder')
                    "
                    :hint-text="
                        $t('bookeepingBank.manualModal.descriptionHint')
                    "
                    rows="6"
                />
            </form-control-group>

            <hr />

            <form-control-group class="px-6 pb-1 pt-5">
                <form-file-upload
                    v-model="document"
                    :max-files="1"
                    :description="
                        $t('bookeepingBank.manualModal.uploadDescription')
                    "
                    :accepted-files="['.pdf', '.png', '.jpg', '.jpeg']"
                    :error-message="documentError"
                />
            </form-control-group>
        </template>

        <template #modal-footer>
            <div class="flex items-center justify-end gap-3">
                <base-button
                    variant="default"
                    :disabled="state.loading"
                    size="sm"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    variant="primary"
                    :loading="state.loading"
                    size="sm"
                    @click="onSave"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
import { AMOUNT_TYPE, BOOKED_TRANSACTION_TYPE } from '@tenant/utils/constants'
import { getContactName } from '@tenant/utils/helper'
import BigNumber from 'bignumber.js'
import { now, shortServerFormat } from '@tenant/utils/day'

const emit = defineEmits(['modal-close', 'create-success'])

const props = defineProps({
    initType: {
        type: String,
        default: null,
    },
    paymentAccount: {
        type: Object,
        required: true,
    },
})

const state = reactive({
    loading: false,
})
const { defaultCurrency } = useCurrencies()
const { uploadStorage } = useUploadStorage()
const { successNotify } = useNotification()
const { t } = useI18n()
const { execute } = useApi('/api/book-manual-transaction', 'POST')

const { handleSubmit, resetForm, setErrors } = useForm()

const { value: type, setValue: setType } = useField('type')
const { value: chartOfAccount, errorMessage: chartOfAccountError } = useField(
    'chart_of_account_id'
)
const { value: exchangeRate, setValue: setExchangeRate } =
    useField('exchange_rate')
const {
    value: amount,
    errorMessage: amountError,
    setValue: setAmount,
} = useField('amount')
const { value: taxRule, errorMessage: taxRuleError } = useField('tax_rule_id')
const { value: taxType, setValue: setTaxType } = useField('tax_type', null, {
    initialValue: AMOUNT_TYPE.NET,
})
const { value: contact, errorMessage: contactError } = useField('contact_id')
const { value: contactData } = useField('contact')
const {
    value: paymentDate,
    errorMessage: paymentDateError,
    setValue: setPaymentDate,
} = useField('payment_date')
const { value: reference, errorMessage: referenceError } = useField('reference')
const { value: description, errorMessage: descriptionError } =
    useField('description')
const { value: document, errorMessage: documentError } = useField('document')

const taxInclusive = computed({
    get: () => {
        return taxType.value === AMOUNT_TYPE.GROSS
    },
    set: (value) => {
        setTaxType(value ? AMOUNT_TYPE.GROSS : AMOUNT_TYPE.NET)
    },
})

const show = computed(() => !!props.initType)

const currency = computed(() => props.paymentAccount.currency)
const taxFilter = computed(() => [
    type.value === BOOKED_TRANSACTION_TYPE.OUTCOME ? 'purchases' : 'sales',
    'no_vat',
])

const { taxRules } = useTaxRules(taxFilter)

watch(
    () => props.initType,
    (value) => {
        setType(value)
    }
)

watch(
    () => show.value,
    (value) => {
        if (value) {
            const { rate, rate_updated_at } = currency.value
            setPaymentDate(shortServerFormat(now()))
            setExchangeRate({
                rate,
                date: shortServerFormat(rate_updated_at),
            })
            return
        }

        resetForm()
    }
)

const factor = computed({
    get() {
        if (!exchangeRate.value?.rate) {
            return 1
        }

        return new BigNumber(1 / exchangeRate.value?.rate)
            .decimalPlaces(6)
            .toNumber()
    },
    set(newValue) {
        exchangeRate.value.rate = 1 / newValue
    },
})

const bookedTypes = computed(() => [
    {
        label: t('bookeepingBank.manualModal.spendMoney'),
        description: t('bookeepingBank.manualModal.youSpendMoney'),
        value: BOOKED_TRANSACTION_TYPE.OUTCOME,
    },
    {
        label: t('bookeepingBank.manualModal.receivedMoney'),
        description: t('bookeepingBank.manualModal.youReceiveMoney'),
        value: BOOKED_TRANSACTION_TYPE.INCOME,
    },
])

const closeModal = () => {
    emit('modal-close')
}

const onSave = handleSubmit(async ({ document, ...values }) => {
    try {
        state.loading = true
        let uploadKey = null
        if (document) {
            uploadKey = await uploadStorage(document)
        }

        const data = await execute({
            data: {
                ...values,
                document: uploadKey,
                payment_account_id: props.paymentAccount.id,
            },
        })
        successNotify({
            title: t('general.success'),
            text: t('bookeepingBank.manualModal.createSuccess'),
        })

        emit('create-success', data)
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        state.loading = false
    }
})

const onSaveFactor = () => {
    setAmount(amount.value * exchangeRate.value.rate)
}

const onContactChange = ({ id, item }) => {
    contact.value = id
    contactData.value = item
}
</script>
