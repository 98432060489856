<template>
    <div class="flex h-40 flex-col border border-gray-300 bg-white">
        <div
            class="flex w-full flex-col gap-4 px-6 py-2.5 text-sm font-medium text-gray-700 lg:px-2.5"
        >
            <div class="flex w-full gap-6 lg:gap-2">
                <div class="flex flex-1 items-center gap-2.5">
                    <span>{{ $t('bookeepingBank.who') }}</span>

                    <form-contact-select
                        v-model="contactId"
                        hide-message
                        :error-message="errorContactId"
                        :placeholder="
                            $t(
                                'bookeepingBank.reconcile.match.nameOfTheContact'
                            )
                        "
                        size="sm"
                        :input-display="getContactName(contact)"
                        :show-existing="
                            contactId !== null &&
                            contactId === transaction.vector_contact_id
                        "
                        @on-contact-changed="onContactChange"
                    />
                </div>
                <div class="flex flex-1 items-center gap-2.5">
                    <span>{{ $t('bookeepingBank.what') }}</span>

                    <form-detail-chart-of-account
                        v-model="chartOfAccountId"
                        hide-message
                        :type="isIncoming ? 'income' : 'expense'"
                        :vector-coa="
                            !transaction?.chart_of_account_id
                                ? transaction.vector_chart_of_account
                                : null
                        "
                        :error-message="errorChartOfAccountId"
                        :placeholder="
                            $t('bookeepingBank.reconcile.match.selectAnAccount')
                        "
                        size="sm"
                        :include-modal="false"
                        @open-modal="state.showAddModal = true"
                    />
                </div>
            </div>
            <div class="flex items-center gap-2.5">
                <span>{{ $t('bookeepingBank.why') }}</span>
                <form-text-input
                    v-model="memo"
                    :error-message="errorMemo"
                    hide-message
                    :placeholder="
                        $t('bookeepingBank.reconcile.match.enterADescription')
                    "
                    size="sm"
                />
            </div>
        </div>
        <div class="flex items-center border-t border-gray-300 py-2.5">
            <base-dropdown
                variant="default"
                content-classes="w-80 p-4 border-gray-300 mt-3"
            >
                <template #btn="{ toggleDropdown, active }">
                    <div
                        class="flex cursor-pointer items-center gap-2 overflow-hidden px-6 text-sm font-medium text-gray-500"
                        :class="{
                            'text-primary-700': active,
                        }"
                        @click="toggleDropdown"
                    >
                        <span class="max-w-xs truncate">
                            {{
                                taxRule
                                    ? taxRule.name
                                    : $t('bookeepingBank.taxRate')
                            }}
                        </span>
                        <base-icon
                            variant="inherit"
                            name="line-icons:arrows:chevron-down"
                        />
                    </div>
                </template>

                <template #default="{ toggleDropdown }">
                    <base-dropdown-item
                        v-for="item in taxRules"
                        :key="item.id"
                        itemClasses="rounded py-2 px-2.5 hover:bg-primary-600 hover:text-white group"
                        :class="{
                            'bg-gray-50': item.id === taxRule?.id,
                        }"
                        @click="onSelectTaxRule(item, toggleDropdown)"
                    >
                        <span class="flex items-center justify-between">
                            {{ item.name }}

                            <base-icon
                                v-if="item.id === taxRule?.id"
                                name="line-icons:general:check"
                                variant="inherit"
                                class="text-primary-700 group-hover:text-white"
                            />
                        </span>
                    </base-dropdown-item>
                </template>
            </base-dropdown>
        </div>

        <bookeeping-accounts-add-modal
            :show="state.showAddModal"
            @modal-close="onCloseAddModal"
            @on-finished="onFinished"
        />
    </div>
</template>

<script setup>
import { getContactName } from '@tenant/utils/helper'

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})

const isIncoming = computed(
    () => props.transaction.transaction_type === 'incoming'
)

const { taxRules } = useTaxRules([isIncoming.value ? 'sales' : 'purchases'])

const taxRule = computed(() => {
    if (!taxRuleId.value) {
        return null
    }

    return taxRules.value.find((i) => i.id === taxRuleId.value)
})

const { value: contactId, errorMessage: errorContactId } =
    useField('contact_id')
const { value: chartOfAccountId, errorMessage: errorChartOfAccountId } =
    useField('chart_of_account_id')
const { value: memo, errorMessage: errorMemo } = useField('memo')
const { value: contact } = useField('contact')
const { value: taxRuleId, setValue: setTaxRuleId } = useField('tax_rule_id')

const onSelectTaxRule = (item, toggleDropdown) => {
    toggleDropdown()
    setTaxRuleId(item?.id)
}

// COA MODAL
const { invalidate } = useFlatChartOfAccounts(1)

const state = reactive({
    showAddModal: false,
})

const onCloseAddModal = () => {
    state.showAddModal = false
}

watch(
    () => props.transaction,
    (transaction) => {
        if (transaction) {
            if (
                !chartOfAccountId.value &&
                !transaction.chart_of_account_id &&
                transaction.vector_chart_of_account
            ) {
                chartOfAccountId.value = transaction.vector_chart_of_account.id
            }

            if (
                !contactId.value &&
                !transaction.contact_id &&
                transaction.vector_contact_id
            ) {
                contactId.value = transaction.vector_contact_id
                contact.value = transaction.vector_contact
            }
        }
    },
    { immediate: true, deep: true }
)

const onFinished = (chartOfAccount) => {
    onCloseAddModal()
    invalidate()
    chartOfAccountId.value = chartOfAccount.id
}
// END COA MODAL

const onContactChange = ({ id, item }) => {
    contactId.value = id
    contact.value = item
}
</script>
