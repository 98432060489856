<template>
    <base-tooltip
        :bordered="true"
        :content="$t('contacts.detail.statement.printTooltip')"
    >
        <span class="cursor-pointer text-sm text-primary-700">
            {{ $t('bookeepingBank.reconcile.moreDetail') }}
        </span>
        <template #content="{ close }">
            <div class="px-3 py-4">
                <div class="mb-5 flex items-center justify-between">
                    <p class="text-lg">
                        {{
                            $t(
                                'bookeepingBank.reconcile.transactionDetails.title'
                            )
                        }}
                    </p>

                    <base-icon
                        @click="close"
                        name="line-icons:general:x"
                        size="lg"
                        variant="gray"
                        class="cursor-pointer"
                    />
                </div>

                <div class="grid grid-cols-2 rounded-md border border-gray-300">
                    <span
                        class="flex justify-end border-b p-2.5 text-sm text-gray-500"
                    >
                        {{
                            $t(
                                'bookeepingBank.reconcile.transactionDetails.transactionDate'
                            )
                        }}
                    </span>
                    <span class="flex border-b p-2.5 text-left text-sm">
                        {{
                            $filters.dateHumanFormat(
                                transaction.date_of_transfer
                            )
                        }}
                    </span>
                    <span
                        class="flex justify-end border-b p-2.5 text-sm text-gray-500"
                    >
                        {{
                            $t(
                                'bookeepingBank.reconcile.transactionDetails.payee'
                            )
                        }}
                    </span>
                    <span class="flex border-b p-2.5 text-left text-sm">
                        {{ transaction?.name }}
                    </span>
                    <span
                        class="flex justify-end border-b p-2.5 text-sm text-gray-500"
                    >
                        {{
                            $t(
                                'bookeepingBank.reconcile.transactionDetails.reference'
                            )
                        }}
                    </span>
                    <span
                        class="flex break-all border-b p-2.5 text-left text-sm"
                    >
                        {{ transaction?.reference }}
                    </span>
                    <span
                        class="flex justify-end border-b p-2.5 text-sm text-gray-500"
                    >
                        {{
                            $t(
                                'bookeepingBank.reconcile.transactionDetails.description'
                            )
                        }}
                    </span>
                    <span class="flex border-b p-2.5 text-left text-sm">
                        {{ transaction?.description }}
                    </span>
                    <span
                        class="flex justify-end border-b p-2.5 text-sm text-gray-500"
                    >
                        {{
                            $t(
                                'bookeepingBank.reconcile.transactionDetails.transactionType'
                            )
                        }}
                    </span>
                    <span class="flex border-b p-2.5 text-left text-sm">
                        <template v-if="isCredit">
                            {{ $t('general.credit').toUpperCase() }}
                        </template>
                        <template v-else>
                            {{ $t('general.debit').toUpperCase() }}
                        </template>
                    </span>
                    <span
                        class="flex justify-end border-b p-2.5 text-sm text-gray-500"
                    >
                        {{
                            $t(
                                'bookeepingBank.reconcile.transactionDetails.amount'
                            )
                        }}
                    </span>
                    <span class="flex border-b p-2.5 text-left text-sm">
                        {{ $filters.numberFormat(transaction.amount) }}
                    </span>
                    <span class="flex justify-end p-2.5 text-sm text-gray-500">
                        {{
                            $t(
                                'bookeepingBank.reconcile.transactionDetails.currency'
                            )
                        }}
                    </span>
                    <span class="flex p-2.5 text-left text-sm">
                        {{ transaction?.currency?.iso }}
                    </span>
                </div>
            </div>
        </template>
    </base-tooltip>
</template>

<script setup>
const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})

const isCredit = computed(() => {
    return props.transaction.amount >= 0
})
</script>
