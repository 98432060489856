<template>
    <div class="relative">
        <div
            class="grid min-h-[250px] grid-cols-[minmax(0px,1fr)_53px_minmax(0px,1fr)] items-start gap-x-6 pb-10"
            :class="{
                'border border-b-0 border-gray-300 bg-white p-6 shadow-xl':
                    activeTab === 'match' && !matched,
            }"
        >
            <bookeeping-bank-detail-reconcile-statement-line
                class="flex-1"
                :transaction="transaction"
                @on-create-rule="onCreateRule"
                :box-highlight="highlight"
            />

            <base-button
                class="justify-center self-center"
                :class="{ invisible: !validSubmit }"
                @click="onSubmit"
            >
                {{ $t('general.ok') }}
            </base-button>

            <bookeeping-bank-detail-reconcile-actions
                ref="reconcileTabs"
                class="flex-1"
                :transaction="transaction"
                @switch-tab="onSwitchTab"
                @find-match="onFindMatch"
                :box-highlight="highlight"
            />
        </div>

        <bookeeping-bank-detail-find-matching-transactions
            v-if="activeTab === 'match' && !matched"
            :transaction="transaction"
            @add-adjustment="onAddAdjustment"
            @close="reconcileTabs.setTab({ name: 'create' })"
        />
    </div>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="onCloseBookCloseModal"
    />
</template>
<script setup>
import { isEmpty } from 'lodash-es'
import { useQueryClient } from '@tanstack/vue-query'
import {
    MATCHING_TRANSACTION_TYPES,
    TRANSACTION_TYPE,
} from '@tenant/utils/constants'

const emit = defineEmits(['on-create-rule'])
const reconcileTabs = ref(null)

const onCreateRule = (transaction) => {
    emit('on-create-rule', transaction)
}

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})

const activeTab = ref('')
const matchItem = ref(null)

const onFindMatch = (item) => {
    matchItem.value = item
}

const adjustmentLineItems = ref([])
const onAddAdjustment = (items) => {
    adjustmentLineItems.value = items.map(({ value }) => value)
}

const onSwitchTab = (tab) => {
    activeTab.value = tab
    if (tab === 'match') {
        updateMatchTabItem(props.transaction.id)
    }
}

const { handleSubmit, setErrors } = useForm()
const queryClient = useQueryClient()

const {
    execute: executeCategorize,
    code: codeCategorize,
    parameters: parameterCategorize,
} = useApi('/api/transactions/categorize', 'POST')

const {
    execute: bookOutgoingPayment,
    code: codeOutgoing,
    parameters: parameterOutgoing,
} = useApi('/api/book-outgoing-payment', 'POST')
const {
    execute: bookIncomingPayment,
    code: codeIncoming,
    parameters: parameterIncoming,
} = useApi('/api/book-incoming-payment', 'POST')

const {
    execute: executeTransferTransaction,
    code: codeTransfer,
    parameters: parameterTransfer,
} = useApi('/api/transactions/transfer', 'POST')

const code = computed(() => {
    return (
        codeCategorize.value ||
        codeOutgoing.value ||
        codeIncoming.value ||
        codeTransfer.value
    )
})

const parameters = computed(() => {
    return (
        parameterCategorize.value ||
        parameterOutgoing.value ||
        parameterIncoming.value ||
        parameterTransfer.value
    )
})

const onCloseBookCloseModal = () => {
    codeCategorize.value = null
    codeOutgoing.value = null
    codeIncoming.value = null
    codeTransfer.value = null

    parameterCategorize.value = null
    parameterOutgoing.value = null
    parameterIncoming.value = null
    parameterTransfer.value = null
}

const contactId = useFieldValue('contact_id')
const chartOfAccountId = useFieldValue('chart_of_account_id')

const transferPaymentAccountId = useFieldValue('payment_account_id')
const transferReference = useFieldValue('reference')

const validSubmit = computed(() => {
    if (activeTab.value === 'match') {
        return !isEmpty(matchItem.value)
    } else if (activeTab.value === 'create') {
        return !isEmpty(contactId.value) && chartOfAccountId.value
    } else if (activeTab.value === 'transfer') {
        return (
            !isEmpty(matchItem.value) ||
            (!isEmpty(transferPaymentAccountId.value) &&
                !isEmpty(transferReference.value))
        )
    }

    return false
})

const outgoingType = computed(() => {
    if (props.transaction.transaction_type === TRANSACTION_TYPE.INCOME) {
        return ''
    } else {
        return 'document_number' in matchItem.value ? 'bill' : 'expense'
    }
})

const highlight = ref(false)
const onSubmit = handleSubmit(async (values) => {
    const { transaction } = props

    try {
        switch (activeTab.value) {
            case 'match': {
                const data = {
                    booked_id: matchItem.value?.id,
                    payment_account_transaction_id: transaction.id,
                    outgoing_type: outgoingType.value,
                    line_items: adjustmentLineItems.value,
                }

                if (transaction.transaction_type === TRANSACTION_TYPE.INCOME) {
                    await bookIncomingPayment({ data })
                } else {
                    await bookOutgoingPayment({ data })
                }

                break
            }

            case 'create':
                await executeCategorize({
                    data: {
                        ...values,
                        payment_account_transaction_id: transaction.id,
                    },
                })
                break
            case 'transfer':
                await executeTransferTransaction({
                    data: {
                        ...values,
                        receiver_transaction_id: matchItem.value?.id,
                        payment_account_transaction_id: transaction.id,
                    },
                })
                break
            case 'discuss':
                break
        }

        // Set highlight boxes for 300ms and then re-fetch data
        highlight.value = true
        setTimeout(async () => {
            await Promise.all([
                queryClient.setQueryData(
                    ['bank-transactions-reconcile'],
                    (data) => ({
                        pages:
                            data?.pages.map((page) => ({
                                ...page,
                                data:
                                    (
                                        page?.data?.filter(
                                            (val) => val.id !== transaction.id
                                        ) ?? []
                                    ).map((val) => ({
                                        ...val,
                                        match_item:
                                            val?.match_item?.id ===
                                            matchItem.value?.id
                                                ? null
                                                : val.match_item,
                                    })) ?? [],
                            })) ?? [],
                        pageParams: data?.pageParams,
                    })
                ),

                queryClient.setQueryData(
                    ['bank-detail-summary', transaction.payment_account_id],
                    (data) => ({
                        ...data,
                        reconcile: data.reconcile > 0 ? data.reconcile - 1 : 0,
                    })
                ),

                queryClient.invalidateQueries({
                    queryKey: ['payment-accounts'],
                }),
            ])
        }, 300)
    } catch ({ errors }) {
        setErrors(errors)
    }
})

const match = computed(() => props.transaction.match_item)
const matched = computed(() =>
    [
        MATCHING_TRANSACTION_TYPES.BILL,
        MATCHING_TRANSACTION_TYPES.EXPENSE,
    ].includes(match.value?.match_type)
)

// Get back to `Create` tab if there's another `Match` tab opened in another item
const { matchTabItem, updateMatchTabItem } = inject('activeMatchTabItem')
watch(
    () => matchTabItem.value,
    (value) => {
        if (value !== props.transaction.id) {
            reconcileTabs.value?.setTab({ name: 'create' })
        }
    }
)
</script>
