<template>
    <div
        class="flex flex-col gap-8 border-b border-gray-300 bg-gray-50 px-6 py-3.5"
    >
        <bookeeping-bank-detail-reconcile-filter ref="filter" />
    </div>

    <div class="flex flex-1 flex-col gap-8 bg-gray-50 p-6">
        <base-loading
            v-if="isFetching"
            class="flex flex-1 items-center justify-center"
        />
        <template v-else>
            <template v-if="transactions?.length">
                <div class="flex gap-6 text-sm font-medium text-gray-700">
                    <span class="flex-1 border-b border-gray-300 pb-2.5">
                        {{ $t('bookeepingBank.reconcile.reviewYourBank') }}
                    </span>
                    <div class="w-13" />
                    <span class="flex-1 border-b border-gray-300 pb-2.5">
                        {{ $t('bookeepingBank.reconcile.thenMatch') }}
                    </span>
                </div>
            </template>

            <div
                class="mx-auto py-6"
                v-else-if="!paymentAccount?.booked_transactions_count"
            >
                <div class="flex gap-6">
                    <base-icon
                        name="line-icons:general:check-circle"
                        class="text-success-600"
                        size="xl"
                    />

                    <div class="pb-6 text-success-600">
                        <p class="mb-1 text-xl font-bold">
                            {{
                                $t(
                                    'bookeepingBank.reconcile.reconciledView.title'
                                )
                            }}
                        </p>
                        <p class="text-sm">
                            {{
                                $t(
                                    'bookeepingBank.reconcile.reconciledView.description'
                                )
                            }}
                        </p>
                    </div>
                </div>

                <div
                    class="flex items-center border-b-[3px] border-t border-gray-900 py-[18px]"
                >
                    <p class="mr-1.5 text-xl font-bold text-gray-900">
                        {{
                            $t(
                                'bookeepingBank.reconcile.reconciledView.statementBalance'
                            )
                        }}
                    </p>
                    <p class="text-sm text-gray-500">
                        {{ $filters.dateHumanFormat(now()) }}
                    </p>
                    <p class="ml-20 text-xl font-bold text-gray-900">
                        {{ bankBalance }}
                    </p>
                </div>
            </div>
            <template v-else-if="hasFilter">
                <div class="flex w-full items-center justify-center py-5">
                    <slot>
                        <div
                            class="flex w-96 flex-col items-center justify-center"
                        >
                            <div
                                class="mb-4 rounded-full border-8 border-primary-50 bg-primary-100 p-2"
                            >
                                <base-icon
                                    size="lg"
                                    variant="primary"
                                    name="line-icons:general:search-lg"
                                />
                            </div>

                            <p
                                class="mb-6 text-center text-md font-medium text-gray-900"
                            >
                                {{
                                    $t(
                                        'bookeepingBank.reconcile.baseOnActivity'
                                    )
                                }}
                            </p>
                            <base-button
                                variant="default"
                                size="sm"
                                @click="onClearSearch"
                            >
                                {{ $t('general.clearSearch') }}
                            </base-button>
                        </div>
                    </slot>
                </div>
            </template>
        </template>

        <div class="flex flex-col">
            <bookeeping-bank-detail-reconcile-item
                v-for="transaction in transactions"
                :key="transaction.id"
                :transaction="transaction"
                @on-create-rule="onCreateRule"
            />
        </div>
    </div>

    <rules-create
        @slide-close="onCloseRuleSlide"
        :is-show="showRuleSlideOutMenu"
        :overwrite-initial-values="overwriteInitialValues"
        :set-highlight="setHighlight"
    />
</template>
<script setup>
import { useInfiniteQuery } from '@tanstack/vue-query'
import { uniqBy } from 'lodash-es'
import {
    CONDITION_OPERATOR,
    MONEY_FLOW,
    RULE_TYPE,
} from '@tenant/utils/constants'
import { symbolCurrency } from '@tenant/core/filter'
import { now } from '@tenant/utils/day'
import { isNil } from 'lodash'

const { t } = useI18n()

const emitter = useEmitter()

const props = defineProps({
    routeProps: {
        type: Object,
        required: true,
    },
})

const filter = ref(null)

const route = useRoute()

const { useApiSearch } = useApiFactory('transactions/cursor')
const { execute } = useApiSearch({}, false)

const paymentAccount = computed(() => props.routeProps)
provide('paymentAccount', paymentAccount)

const paymentConnector = computed(() => paymentAccount.value.payment_connector)
const paymentCurrency = computed(() => paymentAccount.value.currency)
const bankBalance = computed(() => {
    if (!paymentConnector.value || !paymentCurrency.value) {
        return null
    }

    return symbolCurrency(paymentConnector.value.balance, paymentCurrency.value)
})

const q = computed(() => route.query.q)
const dateOfTransfer = computed(() => route.query.date_of_transfer)
const amountMin = computed(() => route.query.amount_min)
const amountMax = computed(() => route.query.amount_max)

const hasFilter = computed(() => {
    if (!isNil(q.value)) {
        return true
    }

    if (!isNil(dateOfTransfer.value)) {
        return true
    }

    return !isNil(amountMin.value) || !isNil(amountMax.value)
})

const {
    data: transactions,
    isFetching,
    fetchNextPage,
    hasNextPage,
} = useInfiniteQuery({
    queryKey: [
        'bank-transactions-reconcile',
        q,
        dateOfTransfer,
        amountMin,
        amountMax,
    ],
    queryFn: ({ pageParam }) => {
        return execute({
            payment_account_id: paymentAccount.value.id,
            cursor: pageParam,
            limit: 10,
            q: q.value,
            filter: {
                date_of_transfer: dateOfTransfer.value,
            },
            payload: {
                amount_min: amountMin.value,
                amount_max: amountMax.value,
            },
        })
    },
    getNextPageParam: ({ next_cursor }) => next_cursor,
    select: (res) =>
        uniqBy(
            res?.pages?.flatMap((x) => x?.data),
            (x) => x?.id
        ),
    refetchOnWindowFocus: false,
    refetchInterval: false,
})

onMounted(() => {
    emitter.on('scroll-end', onLoadMore)
})

onUnmounted(() => {
    emitter.off('scroll-end', onLoadMore)
})

const onLoadMore = () => {
    if (hasNextPage.value) {
        fetchNextPage()
    }
}

watch(
    () => transactions.value?.length,
    (value) => {
        // Load more if there are only 3 transactions
        if (value === 3) {
            onLoadMore()
        }
    }
)

const overwriteInitialValues = ref({})
const setHighlight = ref({})
const showRuleSlideOutMenu = ref(false)

const onOpenRuleSlide = () => {
    showRuleSlideOutMenu.value = true
}

const onCloseRuleSlide = () => {
    showRuleSlideOutMenu.value = false
}

const onCreateRule = ({ item }) => {
    onOpenRuleSlide()
    setHighlight.value = {
        name: t('rules.validation.name.required'),
    }
    overwriteInitialValues.value = {
        money_flow_type: item.amount >= 0 ? MONEY_FLOW.IN : MONEY_FLOW.OUT,
        payment_account_id: paymentAccount.value.id,
        rule_action: {
            transaction_type: item.transaction_type,
            chart_of_account_id: item.chart_of_account_id,
        },
        rule_conditions: [
            {
                type: RULE_TYPE.DESCRIPTION,
                value: {
                    operator: CONDITION_OPERATOR.CONTAINS,
                    value: item.description,
                },
            },
        ],
    }
}

const matchTabItem = ref(null)
const updateMatchTabItem = (item) => {
    matchTabItem.value = item
}
provide('activeMatchTabItem', {
    matchTabItem,
    updateMatchTabItem,
})

const onClearSearch = () => {
    filter.value.onResetFilters()
}
</script>
