<template>
    <div class="h-38 flex flex-col border border-gray-300 bg-white">
        <div
            class="flex w-full flex-col gap-4 px-6 pb-4 pt-2.5 text-sm font-medium text-gray-700"
        >
            <div class="grid grid-cols-5 gap-x-3">
                <div class="col-span-3 pt-2.5">
                    <div class="pb-2.5">
                        <base-badge
                            :label="
                                $t('bookeepingBank.reconcile.create.applyRule')
                            "
                            class="gap-2 !p-0 !px-2"
                            variant="gray"
                        />
                    </div>
                    <div>
                        <div
                            class="flex gap-2 text-sm font-normal text-primary-700 hover:cursor-pointer"
                        >
                            <span @click="openRule">
                                {{ transaction?.rule?.name }}
                            </span>

                            <base-icon
                                name="line-icons:general:link-external-02"
                                variant="hardPrimary"
                                @click="openRule"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-span-2">
                    <form-contact-select
                        v-model="contactId"
                        hide-message
                        :error-message="errorContactId"
                        :label="
                            $t('bookeepingBank.reconcile.create.contactName')
                        "
                        :placeholder="
                            $t('bookeepingBank.reconcile.create.contactName')
                        "
                        :input-display="getContactName(contact)"
                        @on-contact-changed="onContactChange"
                    />
                </div>
            </div>
        </div>
        <div
            class="flex items-center gap-2.5 border-t border-gray-300 py-2.5 pl-6"
        >
            <span
                class="text-sm text-primary-700 hover:cursor-pointer"
                @click="openRuleSlide"
            >
                {{ $t('bookeepingBank.reconcile.create.editRule') }}
            </span>

            <span
                class="text-sm text-gray-500 hover:cursor-pointer"
                @click="onNotApplyRule"
            >
                {{ $t('bookeepingBank.reconcile.create.doNotApplyRule') }}
            </span>
        </div>
    </div>

    <rules-edit
        @slide-close="onCloseRuleSlide"
        :is-show="showRuleSlideOutMenu"
        :rule-id="transaction?.rule_id"
    />
</template>

<script setup>
import { getContactName } from '@tenant/utils/helper'
import { useApi } from '@tenant/composables'

const emit = defineEmits(['on-not-apply-rule'])

const { value: contactId, errorMessage: errorContactId } =
    useField('contact_id')
const { value: chartOfAccountId } = useField('chart_of_account_id')
const { value: memo } = useField('memo')
const { value: contact } = useField('contact')

const { execute: executeNotApply } = useApi(
    '/api/transaction/{transactionId}/not-apply-rule',
    'POST'
)

const router = useRouter()

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})

watch(
    () => props.transaction,
    (transaction) => {
        if (!transaction?.rule?.rule_action) {
            return
        }

        contactId.value = transaction.rule.rule_action.contact_id
        contact.value = transaction.rule.rule_action.contact
        chartOfAccountId.value =
            transaction.rule.rule_action.chart_of_account_id
        memo.value = transaction.rule.rule_action.is_keep_memo
            ? transaction.description + ' ' + transaction.rule.rule_action.memo
            : transaction.rule.rule_action.memo
    },
    { immediate: true }
)

const showRuleSlideOutMenu = ref(false)

const onCloseRuleSlide = () => {
    showRuleSlideOutMenu.value = false
}

const openRuleSlide = () => {
    showRuleSlideOutMenu.value = true
}

const openRule = () => {
    const linkRoute = router.resolve({
        name: 'bookeeping.rules.list',
        query: {
            id: props.transaction.rule_id,
        },
    })

    window.open(linkRoute.href, '_blank')
}

const onContactChange = ({ id, item }) => {
    contactId.value = id
    contact.value = item
}

const onNotApplyRule = async () => {
    await executeNotApply({
        params: {
            transactionId: props.transaction.id,
        },
    })

    emit('on-not-apply-rule')
}
</script>
