<template>
    <div>
        <data-grid
            ref="gridRef"
            :data-source="entities.data"
            :columns="columns"
            :loading="loadingSearch"
            :border-table-header="true"
            :table-borderless="true"
            identify-field="id"
            :empty-text="$t('bookeepingBank.emptyText')"
            :empty-sub="$t('bookeepingBank.emptySubText')"
            custom-classes="border-t-none border-l-none border-r-none rounded-none"
            selectable
            @row-click="onRowClick"
        >
            <template #header-locked>
                <base-tippy
                    placement="top"
                    :content="$t('bookeepingBank.statement.lockedDescription')"
                >
                    <div
                        class="pointer-events-auto flex items-center justify-center gap-1"
                    >
                        {{ $t('bookeepingBank.statement.columns.locked') }}

                        <base-icon
                            name="line-icons:general:help-circle"
                            variant="grayLight"
                            size="sm"
                        />
                    </div>
                </base-tippy>
            </template>
            <template #column-description="{ item }">
                {{
                    item.reference ||
                    item.description ||
                    item.payment_transaction?.memo ||
                    item.payment_transaction?.description
                }}
            </template>

            <template #column-dateOfTransfer="{ item }">
                {{ $filters.dateHumanFormat(item?.payment_date) }}
            </template>

            <template #column-type="{ item }">
                <span>
                    {{
                        $t(
                            `bookeepingBank.statement.transactionType.${item?.payment_transaction?.transaction_type}`
                        )
                    }}
                </span>
            </template>

            <template #column-status="{ item }">
                <span v-if="!item?.unreconciled">
                    <base-badge
                        :label="$t('bookeepingBank.reconciled')"
                        variant="success"
                    />
                </span>
                <span v-else>
                    <base-badge
                        :label="$t('bookeepingBank.unreconciled')"
                        variant="warning"
                    />
                </span>
            </template>

            <template #column-spent="{ item }">
                <span class="whitespace-nowrap text-danger-600">
                    {{
                        item.type === BOOKED_TRANSACTION_TYPE.OUTCOME
                            ? $filters.currency(
                                  item.amount,
                                  item?.payment_transaction?.currency?.iso ||
                                      item.payment_account.currency.iso
                              )
                            : ''
                    }}
                </span>
            </template>

            <template #column-received="{ item }">
                <span class="whitespace-nowrap text-success-600">
                    {{
                        item.type === BOOKED_TRANSACTION_TYPE.INCOME
                            ? $filters.currency(
                                  item.amount,
                                  item?.payment_transaction?.currency?.iso ||
                                      item.payment_account.currency.iso
                              )
                            : ''
                    }}
                </span>
            </template>

            <template #column-source>
                {{ $t('bookeepingBank.statement.source.bankFeed') }}
            </template>

            <template #column-locked="{ item }">
                <base-icon
                    v-if="item.locked_closing_date"
                    variant="inherit"
                    name="line-icons:security:lock-01"
                />
            </template>

            <template #footer>
                <grid-pagination
                    :total="entities.last_page"
                    :page="entities.current_page"
                />
            </template>
        </data-grid>

        <bookeeping-bank-detail-account-transaction-slide-out
            :show="!!selectedTransaction"
            :transaction="selectedTransaction"
            @close="selectedTransaction = null"
            @unreconciled="onUnreconciled"
        />
    </div>
</template>
<script setup>
import { BOOKED_TRANSACTION_TYPE } from '@tenant/utils/constants'

const gridRef = ref(null)
const { t } = useI18n()
const route = useRoute()

useHead({
    title: t('bookeepingBank.title'),
})

const { entities, useApiSearch } = useApiFactory('booked-transactions')
const { loading: loadingSearch, executeRefreshSearch } = useApiSearch({
    filter: {
        'paymentTransaction.payment_account_id': route.params.id,
        payment_account_id: ['OR =', route.params.id],
    },
})

const columns = [
    {
        property: 'dateOfTransfer',
        label: t('bookeepingBank.statement.columns.dateOfTransfer'),
        headerCellClass: 'w-36',
    },
    {
        property: 'description',
        label: t('bookeepingBank.statement.columns.description'),
    },
    {
        property: 'spent',
        label: t('bookeepingBank.statement.columns.spent'),
    },
    {
        property: 'received',
        label: t('bookeepingBank.statement.columns.received'),
    },
    {
        property: 'status',
        label: t('bookeepingBank.statement.columns.status'),
    },
    {
        property: 'locked',
        center: true,
        label: t('bookeepingBank.statement.columns.locked'),
    },
]

const selectedTransaction = ref(null)
const onRowClick = (id, item) => {
    selectedTransaction.value = item
    gridRef.value.closeContent()
}

const onUnreconciled = () => {
    executeRefreshSearch()
    selectedTransaction.value = null
}
</script>
