<template>
    <div class="flex flex-col items-end gap-2.5">
        <base-dropdown
            variant="default"
            contentClasses="w-40"
            v-if="!suppressOptions"
        >
            <template #btn="{ toggleDropdown }">
                <div
                    class="flex cursor-pointer items-center gap-2 text-sm font-medium text-primary-700"
                    @click="toggleDropdown"
                >
                    <span>
                        {{ $t('bookeepingBank.reconcile.options') }}
                    </span>
                    <base-icon
                        variant="inherit"
                        name="line-icons:arrows:chevron-down"
                    />
                </div>
            </template>

            <base-dropdown-item @click="onCreateRule">
                {{ $t('bookeepingBank.reconcile.createRule') }}
            </base-dropdown-item>
        </base-dropdown>
        <div
            class="flex w-full flex-1 overflow-hidden border border-gray-300 bg-white text-md font-normal text-gray-900"
            :class="{ 'border-success-300 !bg-success-100': boxHighlight }"
            style="min-height: 117px"
        >
            <div
                class="flex flex-1 flex-col gap-0.5 overflow-hidden px-6 py-2.5"
            >
                <time class="text-sm text-gray-600">
                    {{ $filters.dateHumanFormat(transaction.date_of_transfer) }}
                </time>
                <span class="truncate">{{ transaction.name }}</span>
                <span class="truncate">{{ transaction.description }}</span>
                <span class="truncate">{{ transaction.reference }}</span>

                <div class="flex items-center" v-if="!suppressDetails">
                    <bookeeping-bank-detail-reconcile-transaction-detail-tooltip
                        :transaction="transaction"
                    />
                </div>
            </div>

            <div
                class="flex w-32 flex-col items-end gap-0.5 border-l border-gray-300 px-6 py-2.5"
            >
                <span class="text-xs text-gray-600">
                    {{ $t('bookeepingBank.reconcile.spent') }}
                </span>
                <span v-if="amount < 0">
                    {{ $filters.numberFormat(Math.abs(amount)) }}
                </span>
            </div>

            <div
                class="flex w-32 flex-col items-end gap-0.5 border-l border-gray-300 px-6 py-2.5"
            >
                <span class="text-xs text-gray-600">
                    {{ $t('bookeepingBank.reconcile.received') }}
                </span>

                <span v-if="amount >= 0">
                    {{ $filters.numberFormat(amount) }}
                </span>
            </div>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['on-create-rule'])

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
    boxHighlight: {
        type: Boolean,
        default: false,
    },
    suppressOptions: {
        type: Boolean,
        default: false,
    },
    suppressDetails: {
        type: Boolean,
        default: false,
    },
})

const chartOfAccountId = useFieldValue('chart_of_account_id')

const onCreateRule = () => {
    emit('on-create-rule', {
        item: {
            ...props.transaction,
            chart_of_account_id: chartOfAccountId.value,
        },
    })
}

const amount = computed(() => props.transaction.amount)
</script>
