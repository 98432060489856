<template>
    <div class="flex flex-col gap-6 p-6">
        <div
            class="flex flex-col overflow-hidden rounded-lg border"
            :class="
                paymentConnector?.expired_connection
                    ? 'border-warning-300'
                    : 'border-gray-200'
            "
        >
            <bookeeping-bank-reconnect-alert
                v-if="paymentConnector?.expired_connection"
                :payment-account="paymentAccount"
            />

            <div
                class="flex justify-between border-b border-gray-200 bg-gray-25 p-6"
            >
                <div class="flex gap-6">
                    <div
                        class="text-md font-medium text-gray-900"
                        v-if="paymentAccount.name"
                    >
                        {{ paymentAccount.name }}
                        <p class="text-sm font-normal text-gray-400">
                            {{ paymentAccount.iban?.slice(-4) }}
                        </p>
                    </div>

                    <img
                        v-if="bankLogo"
                        :alt="paymentAccount.name"
                        :src="bankLogo"
                        class="h-10 w-10 object-contain"
                    />
                </div>

                <div class="flex gap-3">
                    <base-dropdown
                        v-if="paymentAccount.type === ACCOUNT_TYPES.BANK_FEED"
                        variant="default"
                        position="right"
                    >
                        <template #btn-content>
                            {{ $t('bookeepingBank.bankCard.manageAccount') }}

                            <base-icon
                                name="line-icons:arrows:chevron-down"
                                variant="inherit"
                            />
                        </template>

                        <base-dropdown-item
                            v-if="$acl.can('update_bank_accounts')"
                            @click="redirectToManualTransaction"
                        >
                            {{
                                $t(
                                    'bookeepingBank.bankCard.createManualTransaction'
                                )
                            }}
                        </base-dropdown-item>
                        <base-dropdown-item
                            @click="redirectToAccountTransaction"
                        >
                            {{
                                $t(
                                    'bookeepingBank.bankCard.accountTransactions'
                                )
                            }}
                        </base-dropdown-item>
                        <base-dropdown-item @click="redirectToBankStatement">
                            {{ $t('bookeepingBank.bankCard.bankStatements') }}
                        </base-dropdown-item>
                        <base-dropdown-item
                            v-if="$acl.can('update_bank_accounts')"
                            @click="redirectToBankImport"
                        >
                            {{
                                $t(
                                    'bookeepingBank.bankCard.importBankStatement'
                                )
                            }}
                        </base-dropdown-item>
                    </base-dropdown>

                    <template v-else>
                        <base-button
                            size="sm"
                            variant="default"
                            v-if="$acl.can('update_bank_accounts')"
                            @click="redirectToManualTransaction"
                        >
                            {{
                                $t(
                                    'bookeepingBank.bankCard.createManualTransaction'
                                )
                            }}
                        </base-button>
                        <base-button
                            size="sm"
                            variant="default"
                            @click="redirectToAccountTransaction"
                        >
                            {{
                                $t(
                                    'bookeepingBank.bankCard.accountTransactions'
                                )
                            }}
                        </base-button>
                        <base-button
                            size="sm"
                            variant="default"
                            @click="redirectToBankStatement"
                        >
                            {{ $t('bookeepingBank.bankCard.bankStatements') }}
                        </base-button>
                    </template>
                </div>
            </div>
            <div class="flex gap-6 px-6 py-2.5">
                <div
                    class="flex w-52 flex-col pr-6 text-sm font-normal text-gray-500"
                >
                    <template v-if="bankBalance">
                        <span class="text-lg font-medium text-black">
                            {{ bankBalance }}
                        </span>
                        <span>
                            {{ $t('bookeepingBank.bankCard.statementBalance') }}
                        </span>
                    </template>

                    <template v-if="balance">
                        <span class="text-lg font-medium text-black">
                            {{ balance }}
                        </span>
                        <span>
                            {{ $t('bookeepingBank.bankCard.balanceInCybooks') }}
                        </span>
                    </template>

                    <base-button
                        class="my-2.5 self-start"
                        v-if="
                            reconcileItemsCount &&
                            $acl.can('update_bank_accounts')
                        "
                        @click="reconcile(paymentAccount)"
                    >
                        {{
                            $t(
                                'bookeepingBank.bankCard.reconcileBtn',
                                reconcileItemsCount ?? 0
                            )
                        }}
                    </base-button>

                    <div class="my-2.5 flex gap-2" v-if="!reconcileItemsCount">
                        <base-icon
                            name="line-icons:general:check-circle"
                            class="text-success-600"
                        />
                        <p class="text-sm text-success-600">
                            {{ $t('bookeepingBank.reconciled') }}
                        </p>
                    </div>
                </div>
                <div class="flex-1">
                    <base-alert
                        class="my-6 py-6"
                        v-if="!reconcileItemsCount"
                        variant="primary"
                        :title="
                            $t('bookeepingBank.bankCard.noTransactionsText')
                        "
                    />

                    <line-chart
                        v-else-if="paymentAccount.chart"
                        :key="paymentAccount.id"
                        class="w-full flex-1"
                        ref="line"
                        height="160"
                        :chart-data="getChart(paymentAccount?.chart)"
                        :chart-options="chartOptions"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { currency } from '@tenant/utils/helper'
import { ACCOUNT_TYPES, BOOKED_TRANSACTION_TYPE } from '@tenant/utils/constants'

const props = defineProps({
    active: {
        type: Boolean,
        default: false,
    },
    paymentAccount: {
        type: Object,
        default: () => ({}),
    },
})

const router = useRouter()

const paymentConnector = computed(() => props.paymentAccount.payment_connector)
const paymentCurrency = computed(() => props.paymentAccount.currency)
const bankBalance = computed(() => {
    if (!paymentConnector.value || !paymentCurrency.value) {
        return null
    }

    return currency(paymentConnector.value.balance, paymentCurrency.value.iso)
})
const bankLogo = computed(() => {
    if (!paymentConnector.value) {
        return null
    }

    return paymentConnector.value.provider_logo
})

const balance = computed(() => {
    if (!paymentCurrency.value) {
        return null
    }

    return currency(
        props.paymentAccount?.cybooks_balance ?? 0,
        paymentCurrency.value.iso
    )
})

const reconcile = (paymentAccount) => {
    router.push({
        name: 'bookeeping.bank.detail',
        params: {
            id: paymentAccount.id,
        },
    })
}

const reconcileItemsCount = computed(() => {
    return props.paymentAccount?.booked_transactions_count
})

const { t } = useI18n()
const getChart = ({ labels, amounts }) => {
    return {
        labels: (labels || []).map((i) => [i.name]),
        datasets: [
            {
                pointStyle: false,
                label: t('dashboard.cashflow.amount'),
                borderColor: '#7F56D9',
                data: amounts || [],
                tension: 0.4,
                fill: true,
                backgroundColor: (ctx) => {
                    const canvas = ctx.chart.ctx
                    const gradient = canvas.createLinearGradient(0, 0, 0, 300)

                    gradient.addColorStop(0, 'rgba(127, 86, 217, 0.15)')
                    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)')

                    return gradient
                },
            },
        ],
    }
}

const chartOptions = computed(() => ({
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            boxPadding: 10,
        },
        chartAreaBorder: {
            display: false,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            display: true,
            offset: true,
            ticks: {
                color: '#667085',
            },
            grid: {
                drawBorder: false,
                display: false,
            },
            border: {
                display: false,
            },
        },
        y: {
            display: true,
            beginAtZero: true,
            max: ({ chart }) => {
                let max = 5

                chart.data.datasets.forEach((dataset) => {
                    const datasetMax = Math.max(...dataset.data)
                    if (datasetMax === 5) {
                        max = 6
                    } else {
                        max = datasetMax > max ? datasetMax : max
                    }
                })

                return Math.max(chart.options.scales.y.suggestedMax, max)
            },
            ticks: {
                count: 6,
                display: false,
            },
            border: {
                display: false,
            },
            grid: {
                drawBorder: false,
                color: '#F2F4F7',
            },
        },
    },
}))

const redirectToManualTransaction = () => {
    router.push({
        name: 'bookeeping.bank.detail',
        params: {
            id: props.paymentAccount.id,
        },
        query: {
            manual: BOOKED_TRANSACTION_TYPE.OUTCOME,
        },
    })
}

const redirectToAccountTransaction = () => {
    router.push({
        name: 'bookeeping.bank.detail.transactions',
        params: {
            id: props.paymentAccount.id,
        },
    })
}

const redirectToBankStatement = () => {
    router.push({
        name: 'bookeeping.bank.detail.statement',
        params: {
            id: props.paymentAccount.id,
        },
    })
}

const redirectToBankImport = () => {
    router.push({
        name: 'bookeeping.bank.import',
        params: {
            id: props.paymentAccount.id,
        },
    })
}
</script>
