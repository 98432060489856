<template>
    <div class="flex flex-col gap-2.5">
        <base-tabs
            :class="{ '-mx-3 -mt-4 bg-white shadow-xl': chatView }"
            variant="horizontal"
            :items="tabs"
            :tab-class="[
                'border-t border-l border-r',
                chatView ? 'pl-3 pr-3 border-gray-300' : 'border-transparent',
            ]"
            tab-style="underlined"
            :has-margin="false"
        >
            <template #tabs="{ items }">
                <span
                    v-for="item in items"
                    :key="item.name"
                    class="flex cursor-pointer gap-2 px-3 pb-4 text-sm font-medium text-gray-500"
                    :class="{
                        'border-b-2 border-b-primary-700 text-primary-700':
                            activeTab === item.name,
                        'pt-4': chatView,
                    }"
                    @click="setTab(item)"
                >
                    {{ item.label }}

                    <base-badge
                        variant="danger"
                        v-if="item.name === 'discuss' && unAnswered"
                    >
                        <template #label>
                            <div class="flex items-center gap-1.5">
                                <span
                                    class="h-1.5 w-1.5 rounded-full bg-danger-500"
                                />
                                Unanswered
                            </div>
                        </template>
                    </base-badge>
                </span>
            </template>
            <template #content>
                <bookeeping-bank-detail-reconcile-actions-match
                    v-if="activeTab === 'match'"
                    :transaction="transaction"
                    @find-match="onFindMatch"
                />

                <bookeeping-bank-detail-reconcile-actions-create
                    v-else-if="activeTab === 'create' && !hasRule"
                    :transaction="transaction"
                    :class="{
                        'border-success-300 !bg-success-100': boxHighlight,
                    }"
                />

                <bookeeping-bank-detail-reconcile-actions-apply-rule
                    v-else-if="activeTab === 'create' && hasRule"
                    :transaction="transaction"
                    :class="{
                        'border-success-300 !bg-success-100': boxHighlight,
                    }"
                    @on-not-apply-rule="hasRule = false"
                />

                <bookeeping-bank-detail-reconcile-actions-transfer
                    v-else-if="activeTab === 'transfer'"
                    :transaction="transaction"
                    @find-match="onFindMatch"
                />

                <bookeeping-bank-detail-reconcile-actions-discuss
                    v-else
                    :transaction="transaction"
                />
            </template>
        </base-tabs>
    </div>
</template>
<script setup>
import { TRANSACTION_TYPE } from '@tenant/utils/constants'

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
    boxHighlight: {
        type: Boolean,
        default: false,
    },
})

const hasRule = ref(false)

watch(
    () => props.transaction,
    (transaction) => {
        if (!transaction) {
            return
        }

        hasRule.value = !!transaction.rule_id
    },
    { immediate: true }
)

const emit = defineEmits(['switch-tab', 'find-match'])

const { tenantId } = useAuth()
const { t } = useI18n()
const { unAnswers } = useUnAnswerConversation(tenantId)

const tabs = [
    {
        name: 'match',
        label: t('bookeepingBank.reconcile.match.tabs.match'),
    },
    {
        name: 'create',
        label: t('bookeepingBank.reconcile.match.tabs.create'),
    },
    ...(props.transaction.transaction_type === TRANSACTION_TYPE.EXPENSE
        ? [
              {
                  name: 'transfer',
                  label: t('bookeepingBank.reconcile.match.tabs.transfer'),
              },
          ]
        : []),
    {
        name: 'discuss',
        label: t('bookeepingBank.reconcile.match.tabs.discuss'),
    },
]

const match = computed(() => props.transaction.match_item)
const matchType = computed(() => match.value?.match_type)

const activeTab = ref('create')
watchEffect(() => {
    if (!match.value) {
        activeTab.value = 'create'
        return
    }

    if (matchType.value === 'transactions') {
        activeTab.value = 'transfer'
        return
    }

    activeTab.value = 'match'
})

const chatRoom = computed(() => props.transaction.chat_room_id)
const chatView = computed(() => chatRoom.value && activeTab.value === 'discuss')
const unAnswered = computed(() => unAnswers.value?.includes(chatRoom.value))

const setTab = (tab) => {
    activeTab.value = tab.name
}

const onFindMatch = (match) => {
    emit('find-match', match)
}

watch(
    () => activeTab.value,
    (value) => {
        emit('switch-tab', value)
    },
    { immediate: true }
)

defineExpose({
    activeTab,
    setTab,
})
</script>
