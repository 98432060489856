<template>
    <base-loading
        v-if="loading"
        class="flex flex-1 items-center justify-center"
    />

    <base-sticky-heading v-else-if="paymentAccount" class="pb-0 pl-0 pr-0 pt-0">
        <template #heading>
            <div class="flex flex-1 flex-col">
                <bookeeping-bank-reconnect-alert
                    v-if="paymentConnector?.expired_connection"
                    :payment-account="paymentAccount"
                />

                <div
                    class="flex min-h-16 items-center justify-between px-6 py-3"
                >
                    <base-dropdown variant="default">
                        <template #btn="{ toggleDropdown }">
                            <div
                                class="flex cursor-pointer items-center gap-1 text-lg font-medium text-black"
                                @click="toggleDropdown"
                            >
                                {{ paymentAccount.name }}
                                <base-icon
                                    variant="inherit"
                                    name="line-icons:arrows:chevron-down"
                                />
                            </div>
                        </template>

                        <template #default="{ toggleDropdown }">
                            <base-dropdown-item
                                v-for="item in paymentAccounts"
                                :key="item.id"
                                @click="onSelectAccount(toggleDropdown, item)"
                            >
                                {{ item.name }}
                            </base-dropdown-item>
                        </template>
                    </base-dropdown>
                    <div
                        class="flex flex-col"
                        v-if="!paymentConnector?.expired_connection"
                    >
                        <base-button
                            v-if="paymentAccount.type === ACCOUNT_TYPES.ONLINE"
                            variant="default"
                            :loading="
                                loadingRefresh || paymentAccount.processing
                            "
                            @click="onRefreshTransaction"
                        >
                            {{ $t('general.refresh') }}
                        </base-button>

                        <base-dropdown
                            v-else-if="
                                paymentAccount.type ===
                                    ACCOUNT_TYPES.BANK_FEED &&
                                $acl.can('update_bank_accounts')
                            "
                            size="sm"
                            variant="primary"
                        >
                            <template #btn-content>
                                {{ $t('bookeepingBank.addTransaction') }}

                                <base-icon
                                    name="line-icons:arrows:chevron-down"
                                    variant="inherit"
                                />
                            </template>

                            <template #default="{ toggleDropdown }">
                                <base-dropdown-item
                                    @click="
                                        onAddTransaction(
                                            BOOKED_TRANSACTION_TYPE.OUTCOME,
                                            toggleDropdown
                                        )
                                    "
                                >
                                    {{ $t('bookeepingBank.spendMoney') }}
                                </base-dropdown-item>
                                <base-dropdown-item
                                    @click="
                                        onAddTransaction(
                                            BOOKED_TRANSACTION_TYPE.INCOME,
                                            toggleDropdown
                                        )
                                    "
                                >
                                    {{ $t('bookeepingBank.receiveMoney') }}
                                </base-dropdown-item>
                            </template>
                        </base-dropdown>
                    </div>
                </div>
            </div>
        </template>
        <div class="p-6">
            <form-credit-card
                active
                :payment-account="paymentAccount"
                @on-finish-edit="onFinishEdit"
            />
        </div>

        <base-tabs
            v-if="tabs?.length > 0"
            :key="paymentAccount.id"
            class="mx-6 mb-6 flex-1 rounded-md border border-gray-300"
            tab-class="px-6"
            variant="horizontal"
            :items="tabs"
            tab-item-class="pt-5"
            :keep-query="false"
            tab-style="underlined"
            :has-margin="false"
            :content-props="paymentAccount"
        />

        <bookeeping-bank-detail-manual-modal
            :payment-account="paymentAccount"
            :init-type="state.initType"
            @create-success="onCreateManualSuccess"
            @modal-close="onCloseManualModal"
        />
    </base-sticky-heading>
</template>
<script setup>
import { useQuery } from '@tanstack/vue-query'
import {
    ACCOUNT_TYPES,
    BOOKED_TRANSACTION_TYPE,
    ECHO_CHANNELS,
    ECHO_EVENTS,
} from '@tenant/utils/constants'

const emitter = useEmitter()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const { successNotify } = useNotification()

useHead({
    title: t('bookeepingBank.title'),
})

const state = reactive({
    initType: null,
})

const {
    paymentAccounts,
    isLoading: loadingPaymentAccounts,
    isFetchedAfterMount,
} = usePaymentAccounts()
const { updatePaymentAccount, invalidatePaymentAccount } = usePaymentAccount()

const { execute: getSummaryPaymentAccount, loading: loadingSummary } = useApi(
    '/api/payment-accounts/{id}/summary',
    'GET'
)

const { execute: refreshTransaction, loading: loadingRefresh } = useApi(
    '/api/transactions/refresh-transaction',
    'POST'
)

const loading = computed(
    () => loadingSummary.value || loadingPaymentAccounts.value
)

const paymentAccount = computed(() => {
    if (!route.params.id) {
        return null
    }

    return paymentAccounts.value?.find((i) => i.id === route.params.id)
})

const paymentConnector = computed(() => paymentAccount.value?.payment_connector)

watch(
    () => isFetchedAfterMount.value,
    (value) => {
        if (!value) {
            return
        }

        handleRouteAction()
    }
)

const handleRouteAction = () => {
    const { manual, ...restQuery } = route.query
    if (!manual) {
        return
    }

    nextTick(() => {
        state.initType = manual
        router.push({
            query: restQuery,
        })
    })
}

const acl = useAcl()
const { data: tabs } = useQuery({
    queryKey: ['bank-detail-summary', route.params.id],
    queryFn: () =>
        getSummaryPaymentAccount({
            params: {
                id: route.params.id,
            },
        }),
    select: ({ reconcile }) => [
        ...(acl.can('update_bank_accounts')
            ? [
                  {
                      label: t('bookeepingBank.tabs.reconcile'),
                      route: 'bookeeping.bank.detail.reconcile',
                      badgeContent: reconcile,
                  },
              ]
            : []),
        {
            label: t('bookeepingBank.tabs.bankStatements'),
            route: 'bookeeping.bank.detail.statement',
        },
        {
            label: t('bookeepingBank.tabs.accountTransactions'),
            route: 'bookeeping.bank.detail.transactions',
        },
    ],
    refetchOnWindowFocus: false,
    refetchInterval: false,
})

useTenantListen(
    ECHO_CHANNELS.GLOBAL,
    ECHO_EVENTS.PAYMENT_TRANSACTIONS_IMPORTED,
    () => {
        reloadBankDetail()
    }
)

const reloadBankDetail = () => {
    emitter.emit('set-reload')
    invalidatePaymentAccount()
}

const onSelectAccount = (toggleDropdown, item) => {
    toggleDropdown()
    router.replace({
        params: {
            id: item.id,
        },
    })
}

const onRefreshTransaction = () => {
    if (!route.params.id) {
        return
    }

    refreshTransaction({
        data: {
            payment_account_id: route.params.id,
        },
    }).then(() => {
        successNotify({
            title: t('general.success'),
            text: t('bookeepingBank.refreshTransaction'),
        })
    })
}

const onFinishEdit = (newPaymentAccount) => {
    updatePaymentAccount(newPaymentAccount)
}

const onAddTransaction = (type, toggleDropdown) => {
    toggleDropdown()
    state.initType = type
}

const onCreateManualSuccess = () => {
    state.initType = null
    reloadBankDetail()
}

const onCloseManualModal = () => {
    state.initType = null
}
</script>
