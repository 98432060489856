<template>
    <bookeeping-bank-detail-reconcile-actions-discuss-chat
        v-if="transaction.chat_room_id"
        :room="transaction.chat_room"
        :reference="transaction.id"
    />
    <div
        v-else
        class="relative flex h-40 flex-col border border-gray-300 bg-white p-6"
    >
        <form-text-input
            v-model="message"
            :label="$t('bookeepingBank.reconcile.askClientQuestion')"
            :error-message="messageError"
            @keyup.enter="onEnterMessage"
        />
        <div class="mt-3.5 flex">
            <base-badge
                class="cursor-pointer overflow-hidden py-1 pr-2.5"
                @click="onSuggest(suggestMsg)"
            >
                <template #label>
                    <div class="flex items-center gap-2 overflow-hidden">
                        <span
                            class="whitespace-nowrap rounded-full bg-white px-2 py-0.5"
                        >
                            {{ $t('bookeepingBank.reconcile.cybooksSuggest') }}
                        </span>
                        <div class="flex items-center gap-1 overflow-hidden">
                            <span class="flex-1 truncate">
                                {{ suggestMsg }}
                            </span>
                            <base-icon
                                variant="inherit"
                                name="line-icons:arrows:arrow-right"
                            />
                        </div>
                    </div>
                </template>
            </base-badge>
        </div>

        <div v-if="loadingNewDiscuss" class="absolute inset-0">
            <div class="absolute inset-0 bg-white opacity-60" />
            <base-loading
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            />
        </div>
    </div>
</template>

<script setup>
import { useQueryClient } from '@tanstack/vue-query'

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})

const queryClient = useQueryClient()

const suggestMsg = ref('What was the purpose of this coffee shop expense?')

const { setErrors } = useForm()
const { value: message, errorMessage: messageError } = useField('message')

const { execute: executeNewDiscuss, loading: loadingNewDiscuss } = useApi(
    '/api/transactions/discuss',
    'POST'
)

const submitDiscuss = (message) => {
    executeNewDiscuss({
        data: {
            message,
            payment_account_transaction_id: props.transaction.id,
        },
    })
        .catch(({ errors }) => {
            setErrors(errors)
        })
        .then(({ chat_room_id }) => {
            queryClient.setQueryData(
                ['bank-transactions-reconcile'],
                (data) => ({
                    pages:
                        data?.pages.map((page) => ({
                            ...page,
                            data:
                                page?.data?.map((val) => {
                                    if (val.id !== props.transaction.id) {
                                        return val
                                    }

                                    return {
                                        ...val,
                                        chat_room_id,
                                    }
                                }) ?? [],
                        })) ?? [],
                    pageParams: data?.pageParams,
                })
            )
        })
}

const onSuggest = (msg) => {
    submitDiscuss(msg)
}

const onEnterMessage = () => {
    submitDiscuss(message.value)
}
</script>
