const acl = useAcl()

const navigation = [
    {
        id: 'sas-bookeeping',
        path: 'bookeeping',
        label: 'bookeeping.title',
        icon: 'duocolor-icons:finance-&-ecommerce:credit-card-02',
        position: 50,
        permission: () =>
            acl.can('view_chart_of_accounts') ||
            acl.can('view_bank_accounts') ||
            acl.can('view_manual_journals') ||
            acl.can('view_rules'),
    },
    {
        id: 'sas-bookeeping-bank',
        path: 'bookeeping.bank',
        label: 'bookeepingBank.title',
        parent: 'sas-bookeeping',
        position: 1,
        permission: 'view_bank_accounts',
    },
]

export default navigation
