<template>
    <div
        class="absolute top-[56px] z-50 flex max-h-[410px] w-full flex-col border border-gray-300 border-t-gray-200 bg-white"
    >
        <partial-chat-items
            v-if="room"
            :room="room"
            :key="room.id"
            :reference="reference"
            class="px-6 py-7"
            full-width
        />

        <div v-else class="grow" />

        <!-- Footer form -->
        <div class="border-t px-6 py-4">
            <form class="flex gap-3" @submit.prevent="sendMessage">
                <form-text-input
                    v-model="message"
                    :error-message="errorMessage"
                    hide-message
                    class="grow"
                    :placeholder="$t('chat.chatWindow.messageInputPlaceholder')"
                    autofocus
                />
                <base-button
                    type="submit"
                    class="p-3"
                    icon="line-icons:communication:send-01"
                    :disabled="disabledSend"
                    :loading="loadingSend"
                    :has-padding="false"
                />
            </form>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    room: {
        type: Object,
        required: true,
    },
    reference: {
        type: String,
        required: true,
    },
})

const emitter = useEmitter()

const { message, errorMessage, disabledSend, loadingSend, onSendMessage } =
    useMessage(props.room)

const sendMessage = () => {
    onSendMessage().then((message) => {
        emitter.emit('sent-message', {
            room_id: props.room.id,
            message,
        })
    })
}
</script>
