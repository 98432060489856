<template>
    <base-sticky-heading :title="$t('bookeepingBank.title')" class="py-6">
        <template #right>
            <div class="flex gap-3">
                <base-button
                    v-if="$acl.can('view_rules')"
                    size="md"
                    variant="default"
                    label="Bank Rules"
                    @click="$router.push({ name: 'bookeeping.rules' })"
                />

                <base-button
                    variant="primary"
                    size="sm"
                    @click="onAddBank"
                    v-if="$acl.can('create_bank_accounts')"
                >
                    {{ $t('bookeepingBank.addBankAccount') }}
                </base-button>
            </div>
        </template>

        <div class="flex items-center justify-center p-6" v-if="loading">
            <base-loading class="flex items-center justify-center" />
        </div>

        <template v-else>
            <template v-if="result && result.data.length > 0">
                <bookeeping-bank-card
                    v-for="paymentAccount in result.data"
                    :key="paymentAccount.id"
                    :payment-account="paymentAccount"
                />
            </template>

            <div
                v-else
                class="m-6 flex flex-col items-center justify-center rounded-lg border border-gray-200 p-6"
            >
                <div
                    class="rounded-full border-6 border-primary-50 bg-primary-100 p-2"
                >
                    <base-icon
                        name="line-icons:finance-&-ecommerce:bank"
                        variant="primary"
                    />
                </div>
                <span class="mb-1 text-md font-medium text-gray-900">
                    {{ $t('bookeepingBank.noBankAccount') }}
                </span>
                <span class="mb-6 text-sm font-normal text-gray-500">
                    {{ $t('bookeepingBank.noBankAccountHint') }}
                </span>
                <base-button
                    variant="primary"
                    icon="line-icons:general:plus"
                    @click="onAddBank"
                >
                    {{ $t('bookeepingBank.addBankAccount') }}
                </base-button>
            </div>
        </template>
    </base-sticky-heading>
</template>
<script setup>
const { t } = useI18n()
const { execute, result, loading } = useApi('api/payment-accounts', 'GET')

useHead({
    title: t('bookeepingBank.title'),
})

onMounted(() => {
    execute()
})

const router = useRouter()
const onAddBank = () => {
    router.push({
        name: 'settings.accounting.payment-account.connect-bank',
    })
}
</script>
