<template>
    <base-modal
        :show="show"
        :loading="loading"
        size="md"
        @modal-close="closeModal"
    >
        <template #container>
            <div class="flex flex-col p-6">
                <div class="mb-2 flex justify-between">
                    <h5 class="text-lg font-medium text-gray-900">
                        {{ $t('bookeepingBank.statement.deleteStatementLine') }}
                    </h5>
                    <button @click.prevent="closeModal">
                        <base-icon
                            name="line-icons:general:x"
                            size="lg"
                            variant="gray"
                        />
                    </button>
                </div>
                <div
                    class="mb-8 flex flex-col gap-3 text-sm font-normal text-gray-500"
                >
                    <p>
                        {{
                            $t(
                                'bookeepingBank.statement.areYouSureWantToDeleteStatement'
                            )
                        }}
                    </p>
                    <p class="font-semibold">
                        {{ $t('bookeepingBank.statement.generallyThisIsOnly') }}
                    </p>
                    <p>
                        {{
                            $t(
                                'bookeepingBank.statement.ifThisIsAPersonalExpense'
                            )
                        }}
                    </p>
                </div>
                <div class="flex items-center justify-end gap-3">
                    <base-button
                        variant="danger"
                        :loading="loading"
                        @click="onConfirmDelete"
                    >
                        {{ $t('general.delete') }}
                    </base-button>

                    <base-button
                        variant="default"
                        :disabled="loading"
                        @click="closeModal"
                    >
                        {{ $t('general.cancel') }}
                    </base-button>
                </div>
            </div>
        </template>
    </base-modal>
</template>
<script setup>
const emit = defineEmits(['modal-close', 'on-finish'])

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    selectedItems: {
        type: Array,
        default: () => [],
    },
})

const { execute, loading } = useApi('/api/transactions/remove', 'POST')

const closeModal = () => {
    emit('modal-close')
}

const onConfirmDelete = () => {
    execute({
        data: {
            ids: props.selectedItems.map((i) => i.id),
        },
    }).then(() => {
        emit('on-finish')
    })
}
</script>
