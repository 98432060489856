<template>
    <h5 class="mb-6 text-xl font-semibold text-gray-900">
        {{ $t('bookeepingBank.import.reviewAndComplete') }}
    </h5>
    <p class="mb-2.5 text-md font-semibold text-gray-900">
        {{ $t('bookeepingBank.import.summaryOfTransactions') }}
    </p>
    <p class="mb-6 text-sm font-normal text-gray-500">
        {{ $t('bookeepingBank.import.checkForDuplicates') }}
    </p>

    <bookeping-bank-import-review-grid :transactions="successTransactions" />

    <template v-if="errorTransactions && errorTransactions.length > 0">
        <h5 class="mb-6 text-xl font-semibold text-gray-900">
            {{ $t('bookeepingBank.import.errors') }}
        </h5>
        <p class="mb-2.5 text-xl font-semibold text-gray-900">
            {{ $t('bookeepingBank.import.summaryOfTransactions') }}
        </p>
        <p class="mb-6 text-sm font-normal text-gray-500">
            {{ $t('bookeepingBank.import.transactionWontBeImported') }}
        </p>

        <bookeping-bank-import-review-grid :transactions="errorTransactions" />
    </template>
</template>
<script setup>
import { useAssignTransactions } from '@tenant/modules/tenant/bookeeping-bank/compositions/use-import-transactions'

const { totalRows, getTransaction } = useAssignTransactions()

const transactions = computed(() =>
    Array.from(Array(totalRows.value).keys()).map(getTransaction)
)

const successTransactions = computed(() =>
    transactions.value.filter((i) => i.success)
)

const errorTransactions = computed(() =>
    transactions.value.filter((i) => !i.success)
)
</script>
