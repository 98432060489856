<template>
    <div
        class="flex h-40 w-full flex-1 bg-success-100 text-md font-normal text-gray-900"
    >
        <div
            class="flex flex-1 gap-2.5 overflow-x-auto border border-success-300 px-6 py-2.5"
            style="height: 160px"
        >
            <base-icon
                class="text-gray-600"
                name="line-icons:files:file-06"
                variant="inherit"
            />
            <div class="flex flex-1 flex-col overflow-x-auto">
                <time class="text-sm text-gray-600">
                    {{ $filters.dateHumanFormat(getDateDocument(match)) }}
                </time>
                <span>
                    {{
                        $t('bookeepingBank.document_for.transaction', {
                            amount: $filters.symbolCurrency(
                                getAmount(match),
                                transaction.currency
                            ),
                        })
                    }}
                </span>
                <span class="truncate">
                    {{
                        $t('bookeepingBank.ref', {
                            number: getDocumentNumber(match),
                        })
                    }}
                </span>
            </div>
        </div>

        <div
            class="flex w-32 flex-col items-end gap-0.5 border border-l border-l-0 border-gray-300 border-success-300 px-6 py-2.5"
        >
            <span class="text-xs text-gray-600">
                {{ $t('bookeepingBank.reconcile.spent') }}
            </span>
            <span v-if="!isIncome">
                {{ $filters.numberFormat(getAmount(match)) }}
            </span>
        </div>

        <div
            class="flex w-32 flex-col items-end gap-0.5 border border-l border-l-0 border-gray-300 border-success-300 px-6 py-2.5"
        >
            <span class="text-xs text-gray-600">
                {{ $t('bookeepingBank.reconcile.received') }}
            </span>
            <span v-if="isIncome">
                {{ $filters.numberFormat(getAmount(match)) }}
            </span>
        </div>
    </div>
</template>

<script setup>
import { TRANSACTION_TYPE } from '@tenant/utils/constants'

const props = defineProps({
    transaction: {
        type: Object,
        default: null,
    },
})

const match = computed(() => props.transaction.match_item)

const getAmount = (match) => {
    return match.amount
}

const getDocumentNumber = (match) => {
    return match.reference
}

const getDateDocument = (match) => {
    return match.date_of_transfer
}

const isIncome = computed(() => {
    return match.value.transaction_type === TRANSACTION_TYPE.INCOME
})
</script>
