<template>
    <div class="flex items-center justify-center pb-8">
        <div
            class="relative flex flex-1 items-center justify-center"
            v-for="(step, idx) in steps"
            :key="idx"
        >
            <div
                class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2"
                :class="
                    isAlreadyDone(idx)
                        ? 'border-primary-50 bg-primary-50'
                        : isActiveStep(idx)
                        ? 'border-primary-100 bg-primary-50'
                        : 'border-gray-50 bg-gray-50'
                "
            >
                <div v-if="isAlreadyDone(idx)">
                    <base-icon name="other:success" size="xl" />
                </div>
                <div
                    v-else
                    class="h-2 w-2 rounded-full"
                    :class="
                        isActiveStep(idx) ? 'bg-primary-600' : 'bg-gray-200'
                    "
                />

                <span
                    class="absolute top-10 whitespace-nowrap text-md font-medium"
                    :class="
                        isActiveStep(idx) ? 'text-primary-700' : 'text-gray-900'
                    "
                >
                    {{ step.label }}
                </span>
            </div>

            <div
                v-if="idx === 0"
                class="absolute left-1/2 right-0 top-1/2 h-0.5 -translate-y-1/2 bg-gray-200"
            />

            <div
                v-else-if="idx === steps.length - 1"
                class="absolute left-0 right-1/2 top-1/2 h-0.5 -translate-y-1/2 bg-gray-200"
            />

            <div
                v-else
                class="absolute left-0 right-0 top-1/2 h-0.5 -translate-y-1/2 bg-gray-200"
            />
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    steps: {
        type: Array,
        default: () => [],
    },
    activeStep: {
        type: Number,
        default: 0,
    },
})

const isActiveStep = (stepIdx) => {
    return stepIdx + 1 === props.activeStep
}

const isAlreadyDone = (stepIdx) => {
    return stepIdx + 1 < props.activeStep
}
</script>
