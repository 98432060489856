<template>
    <div class="flex flex-col items-end gap-2.5">
        <div
            class="flex w-full flex-1 overflow-hidden border border-gray-300 bg-white text-md font-normal text-gray-900"
            :class="{ 'border-success-300 !bg-success-100': boxHighlight }"
            style="min-height: 117px"
        >
            <div
                class="flex flex-1 flex-col gap-0.5 overflow-hidden px-6 py-2.5"
            >
                <time class="text-sm text-gray-600">
                    {{
                        $filters.dateHumanFormat(
                            transaction.payment_transaction.date_of_transfer
                        )
                    }}
                </time>
                <span class="truncate">{{
                    transaction.payment_transaction.description
                }}</span>
                <span class="truncate">{{
                    transaction.payment_transaction.reference
                }}</span>
            </div>

            <div
                class="flex w-32 flex-col items-end gap-0.5 border-l border-gray-300 px-6 py-2.5"
            >
                <span class="text-xs text-gray-600">
                    {{ $t('bookeepingBank.reconcile.spent') }}
                </span>
                <span v-if="transaction?.type === 'outcome'">
                    {{ $filters.numberFormat(Math.abs(amount)) }}
                </span>
            </div>

            <div
                class="flex w-32 flex-col items-end gap-0.5 border-l border-gray-300 px-6 py-2.5"
            >
                <span class="text-xs text-gray-600">
                    {{ $t('bookeepingBank.reconcile.received') }}
                </span>

                <span v-if="transaction?.type === 'income'">
                    {{ $filters.numberFormat(amount) }}
                </span>
            </div>
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
    boxHighlight: {
        type: Boolean,
        default: false,
    },
})
const amount = computed(() => props.transaction.amount)
</script>
