<template>
    <div class="rounded-md border border-gray-200">
        <bookeping-bank-import-settings-columns-item
            v-for="columnNumber in totalColumns"
            :key="columnNumber"
            :column-number="columnNumber"
            :page="state.page"
        />

        <div class="flex items-center justify-between p-3 px-6">
            <span class="text-sm font-medium text-gray-700">
                {{
                    $t('bookeepingBank.import.showingStatement', {
                        page: state.page,
                        total: totalRows,
                    })
                }}
            </span>
            <div class="flex gap-3">
                <base-button variant="default" size="sm" @click="onPrevious">
                    {{ $t('general.previous') }}
                </base-button>
                <base-button variant="default" size="sm" @click="onNext">
                    {{ $t('general.next') }}
                </base-button>
            </div>
        </div>
    </div>
</template>
<script setup>
const skipHeading = useFieldValue('skip_heading')
const fileData = useFieldValue('file_data')

const state = reactive({
    page: 1,
})

const totalColumns = computed(() => {
    if (!fileData.value || fileData.value.length === 0) {
        return 0
    }

    return fileData.value[0]?.length || 0
})

const totalRows = computed(() => {
    if (!fileData.value) {
        return 0
    }

    return fileData.value.length - withColumnHeading.value
})

const withColumnHeading = computed(() => {
    if (skipHeading.value) {
        return 1
    }

    return 0
})

const onPrevious = () => {
    if (state.page === 1) {
        return
    }

    state.page--
}

const onNext = () => {
    if (state.page >= totalRows.value) {
        return
    }

    state.page++
}
</script>
