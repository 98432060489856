<template>
    <bookeeping-bank-detail-reconcile-match-transaction-item
        :transaction
        v-if="matched"
    />

    <div
        v-else
        class="grid h-40 grid-cols-2 flex-col gap-6 border border-gray-300 bg-white p-5"
    >
        <form-single-select
            v-model="bankAccount"
            :label="$t('bookeepingBank.reconcile.transfer.bankAccountLabel')"
            :placeholder="
                $t('bookeepingBank.reconcile.transfer.bankAccountLabel')
            "
            :options="accounts"
            option-value="id"
            option-label="name"
            :error-message="errorPaymentAccount"
        />

        <form-text-input
            v-model="reference"
            :label="$t('bookeepingBank.reconcile.transfer.referenceLabel')"
            :placeholder="
                $t('bookeepingBank.reconcile.transfer.referencePlaceholder')
            "
            :error-message="errorReference"
        />
    </div>
</template>

<script setup>
import { MATCHING_TRANSACTION_TYPES } from '@tenant/utils/constants'

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})
const emit = defineEmits(['find-match'])

const paymentAccount = inject('paymentAccount')

const { paymentAccounts } = usePaymentAccounts(props.transaction.currency_id)

const accounts = computed(() =>
    paymentAccounts.value.filter(
        (account) => account.id !== paymentAccount.value.id
    )
)

const { value: bankAccount, errorMessage: errorPaymentAccount } =
    useField('payment_account_id')
const { value: reference, errorMessage: errorReference } = useField(
    'reference',
    undefined,
    {
        initialValue: props.transaction.reference,
    }
)

const match = computed(() => props.transaction.match_item)
const matched = computed(
    () => match.value?.match_type === MATCHING_TRANSACTION_TYPES.TRANSACTION
)

watch(
    () => match.value,
    (value) => {
        if (value) {
            emit('find-match', value)
        }
    },
    { immediate: true }
)
</script>
