<template>
    <div class="flex items-center gap-2.5">
        <form-text-input
            :model-value="filterValues.name"
            size="sm"
            :placeholder="$t('bookeepingBank.reconcile.searchPlaceholder')"
            icon-name="line-icons:general:search-sm"
            @input="onInput($event.target.value)"
        />
        <div class="relative">
            <div
                class="flex cursor-pointer items-center gap-2 text-primary-700"
                @click="toggleShowFilter"
            >
                <base-icon name="other:filter-funnel-01" variant="inherit" />
                <span class="text-sm font-medium">
                    {{ $t('general.filter') }}
                </span>
            </div>

            <div
                v-if="state.showFilter"
                class="absolute right-0 top-full z-10 mt-3.5 min-w-112 bg-white"
            >
                <div class="rounded-md border border-gray-300">
                    <div
                        class="flex items-center justify-between px-5 py-3.5 text-gray-900"
                    >
                        <span class="text-md font-semibold">
                            {{ $t('general.filter') }}
                        </span>
                        <span class="text-sm font-medium">
                            {{
                                $t('general.filtersApplied', {
                                    number: totalFilters,
                                })
                            }}
                        </span>
                    </div>
                    <div class="border-b border-t border-gray-300 px-5 py-3.5">
                        <form-control-group>
                            <form-control-group-row>
                                <form-date-picker
                                    v-model="fromValue"
                                    :label="$t('general.startDate')"
                                    size="sm"
                                    single
                                />
                                <form-date-picker
                                    v-model="toValue"
                                    :label="$t('general.endDate')"
                                    size="sm"
                                    single
                                />
                            </form-control-group-row>
                            <form-control-group-row class="items-end">
                                <form-number-input
                                    :model-value="filterValues.amount_min"
                                    v-model="filterValues.amount_min"
                                    label="Amount range"
                                    placeholder="Min"
                                    size="sm"
                                    @update:model-value="
                                        onChangeAmount('amount_min', $event)
                                    "
                                />
                                <form-number-input
                                    :model-value="filterValues.amount_max"
                                    placeholder="Max"
                                    size="sm"
                                    @update:model-value="
                                        onChangeAmount('amount_max', $event)
                                    "
                                />
                            </form-control-group-row>
                        </form-control-group>
                    </div>
                    <div class="flex items-center justify-between px-5 py-3.5">
                        <span
                            class="cursor-pointer text-sm font-medium text-primary-700"
                            @click="onResetFilters"
                        >
                            {{ $t('general.resetFilters') }}
                        </span>
                        <base-button
                            variant="default"
                            size="sm"
                            @click="onApply"
                        >
                            {{ $t('general.apply') }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useDebounceFn, useEventListener } from '@vueuse/core'
import { cloneDeep, toPairs } from 'lodash-es'
import { isNil } from 'lodash'

const queries = {
    name: 'q',
    date_of_transfer: 'date_of_transfer',
    amount_min: 'amount_min',
    amount_max: 'amount_max',
}

const route = useRoute()
const router = useRouter()

const state = reactive({
    showFilter: false,
})

const {
    filterQueries,
    filterValues,
    filterCount,
    updateFilter,
    useRangeFilter,
    resetFilters,
    upsertQuery,
} = useFilters(queries)

const { fromValue, toValue, countTotal } = useRangeFilter(
    'date_of_transfer',
    'date_of_transfer',
    true,
    false
)

const onInput = useDebounceFn(
    async (value) => updateFilter(queries.name, value, true),
    300
)

const onChangeAmount = (field, value) => {
    updateFilter(queries[field], value, false)
}

const totalFilters = computed(() => {
    let total = countTotal(route.query.date_of_transfer)

    if (!isNil(route.query.amount_min)) {
        total++
    }
    if (!isNil(route.query.amount_max)) {
        total++
    }

    return total
})

useEventListener(document, 'keydown', (e) => {
    if (!state.showFilter || e.key !== 'Escape') {
        return
    }

    toggleShowFilter()
})

const toggleShowFilter = () => {
    state.showFilter = !state.showFilter
}

const onResetFilters = async () => {
    resetFilters(true)
    fromValue.value = null
    toValue.value = null
}

const onApply = () => {
    const query = cloneDeep(filterQueries.value)

    toPairs(queries).forEach(([, queryPath]) => {
        upsertQuery(query, queryPath, query[queryPath])
    })

    router.replace({ query: query })
}

defineExpose({
    filterValues,
    filterCount,
    onResetFilters,
})
</script>
