export const useBankConnect = () => {
    const { execute, loading } = useApi(
        '/api/payment-accounts/provider-bank/reconnect',
        'POST'
    )

    const reconnectBank = (paymentAccountId) => {
        return execute({
            data: {
                payment_account_id: paymentAccountId,
            },
        }).then((url) => {
            window.location.href = url

            return url
        })
    }

    return {
        reconnectBank,
        loading,
    }
}
