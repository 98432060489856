import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'bookeeping.bank',
        path: '/bookeeping/bank',
        redirect: {
            name: 'bookeeping.bank.list',
        },
        children: [
            {
                name: 'bookeeping.bank.list',
                path: 'list',
                component: () => import('./pages/bookeeping-bank-list.vue'),
            },
            {
                name: 'bookeeping.bank.import',
                path: 'import/:id',
                component: () => import('./pages/bookeeping-bank-import.vue'),
                redirect: {
                    name: 'bookeeping.bank.import.upload',
                },
                children: [
                    {
                        name: 'bookeeping.bank.import.upload',
                        path: 'upload',
                        component: () =>
                            import('./views/bookeeping-bank-import-upload.vue'),
                    },
                    {
                        name: 'bookeeping.bank.import.settings',
                        path: 'settings',
                        component: () =>
                            import(
                                './views/bookeeping-bank-import-settings.vue'
                            ),
                    },
                    {
                        name: 'bookeeping.bank.import.review',
                        path: 'review',
                        component: () =>
                            import('./views/bookeeping-bank-import-review.vue'),
                    },
                ],
            },
            {
                name: 'bookeeping.bank.detail',
                path: 'detail/:id',
                component: () => import('./pages/bookeeping-bank-detail.vue'),
                redirect: {
                    name: 'bookeeping.bank.detail.reconcile',
                },
                children: [
                    {
                        name: 'bookeeping.bank.detail.reconcile',
                        path: 'reconcile',
                        component: () =>
                            import(
                                './views/bookeeping-bank-detail-reconcile.vue'
                            ),
                        meta: {
                            permission: 'update_bank_accounts',
                        },
                    },
                    {
                        name: 'bookeeping.bank.detail.statement',
                        path: 'statement',
                        component: () =>
                            import(
                                './views/bookeeping-bank-detail-statement.vue'
                            ),
                    },
                    {
                        name: 'bookeeping.bank.detail.transactions',
                        path: 'transactions',
                        component: () =>
                            import(
                                './views/bookeeping-bank-detail-transactions.vue'
                            ),
                    },
                ],
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_bank_accounts',
    },
}
