import {
    IMPORT_FIELDS,
    INCOME_FIELDS,
    OUTCOME_FIELDS,
} from '@tenant/modules/tenant/bookeeping-bank/utils/constants'
import { every, isEmpty, isNumber } from 'lodash'
import { validDate } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'

export const useAssignTransactions = () => {
    const { t } = useI18n()
    const assignColumns = useFieldValue('assign_columns')
    const fileData = useFieldValue('file_data')
    const skipHeading = useFieldValue('skip_heading')

    const formattedFileData = computed(() => {
        let data = fileData.value
        if (!data) {
            return null
        }

        data = [...data]

        if (skipHeading.value) {
            data.shift()
        }

        return data
    })

    const totalRows = computed(() => {
        if (!formattedFileData.value) {
            return 0
        }

        return formattedFileData.value.length
    })

    const isValidSettings = computed(() =>
        Array.from(Array(totalRows.value).keys()).every(
            (i) => !every(getTransaction(i), 'success')
        )
    )

    const getTransaction = (idx) => {
        if (!formattedFileData.value || !assignColumns.value) {
            return {
                success: false,
            }
        }

        let transaction = {}
        Object.values(IMPORT_FIELDS).forEach((field) => {
            transaction = {
                ...transaction,
                [field]: getTransactionField(field, idx),
            }
        })

        let errors = {}
        if (
            !validTransactionType(transaction[IMPORT_FIELDS.transaction_type], [
                ...OUTCOME_FIELDS,
                ...INCOME_FIELDS,
            ])
        ) {
            errors = {
                ...errors,
                transaction_type: t('bookeepingBank.import.thisFieldIsInvalid'),
            }
        }

        const amount = transaction[IMPORT_FIELDS.amount]
        if (!isNumber(amount)) {
            errors = {
                ...errors,
                amount: t('bookeepingBank.import.thisFieldIsInvalid'),
            }
        }

        const date_of_transfer = transaction[IMPORT_FIELDS.date_of_transfer]
        if (!validDate(date_of_transfer, DATE_SERVER_FORMAT, true)) {
            errors = {
                ...errors,
                date_of_transfer: t('bookeepingBank.import.dateIsInvalid', {
                    format: DATE_SERVER_FORMAT,
                }),
            }
        }

        if (!isEmpty(errors)) {
            return {
                success: false,
                errors,
                ...transaction,
            }
        }

        return {
            success: amount !== 0,
            ...transaction,
        }
    }

    const getTransactionField = (field, idx) => {
        const currentFilePage = formattedFileData.value[idx] || {}
        const columnByField = getColumnByField(field)
        if (!columnByField) {
            return null
        }

        return currentFilePage[columnByField - 1]
    }

    const getColumnByField = (field) => {
        const dataAssign = assignColumns.value || {}

        const fieldData = dataAssign[field] || null
        if (!fieldData) {
            return null
        }

        return fieldData.column
    }

    const validTransactionType = (type, transactionTypes) => {
        if (!type) {
            return true
        }

        return transactionTypes.includes(`${type}`.toLowerCase())
    }

    return {
        assignColumns,
        formattedFileData,
        skipHeading,
        totalRows,
        isValidSettings,
        getTransaction,
        getTransactionField,
        getColumnByField,
        validTransactionType,
    }
}
