<template>
    <div class="flex px-6">
        <div class="w-full">
            <div v-for="item in feeds" :key="item.id">
                <feed-item
                    v-if="item.log_name === 'default'"
                    :causer="item.causer"
                    :action="item.description"
                    :subject-id="item.subject_id"
                    :subject-type="item.subject_type"
                    :properties="item.properties"
                    :subject="item.subject"
                    :old-attributes="item.properties.old"
                    :new-attributes="item.properties.attributes"
                    :event="item.event"
                    snippet="bookeepingBank"
                    :created-at="item.created_at"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})

const emitter = useEmitter()

const { execute, result: feeds } = useApi(
    `/api/booked-transactions/{id}/feed-histories`,
    'GET'
)

onMounted(async () => {
    emitter.emit('set-loading', true)
    execute({
        params: { id: props.transaction.id },
    }).finally(() => {
        emitter.emit('set-loading', false)
    })
})
</script>
