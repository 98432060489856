<template>
    <div class="px-6 pb-6" id="transaction-detail">
        <div class="flex gap-2.5 border-b border-gray-200 py-6 print:hidden">
            <template v-if="$acl.can('update_bank_accounts')">
                <base-button
                    v-if="transaction.manual"
                    variant="default"
                    :label="$t('general.edit')"
                    size="sm"
                    @click="emit('on-click-edit')"
                />
                <base-button
                    v-if="!transaction.unreconciled"
                    variant="default"
                    :label="
                        $t(
                            'bookeepingBank.accountTransactions.slideOut.unreconcileBtn'
                        )
                    "
                    size="sm"
                    @click="showUnreconcileModal = true"
                    :loading="isReconciling"
                />
            </template>
            <base-button
                variant="default"
                icon="line-icons:media-&-devices:printer"
                size="sm"
                @click="printContent('transaction-detail')"
            />
        </div>

        <div class="border-b border-gray-200 py-6">
            <p
                class="mb-1 text-lg"
                :class="[
                    transaction.type === 'income'
                        ? 'text-success-600'
                        : 'text-danger-600',
                ]"
                v-if="transaction?.payment_transaction?.currency"
            >
                {{
                    $filters.symbolCurrency(
                        transaction.amount,
                        transaction.payment_transaction.currency
                    )
                }}
            </p>
            <p class="mb-1 text-sm text-gray-700" v-if="taxLabel">
                {{
                    $t('bookeepingBank.accountTransactions.slideOut.taxAmount')
                }}
                {{ taxLabel }}
            </p>
            <p
                class="mb-2.5 text-sm text-gray-500"
                v-if="transaction.chart_of_account_id"
            >
                on
                {{ $filters.dateHumanFormat(transaction.payment_date) }}
            </p>
            <base-badge
                v-if="transaction.chart_of_account_id"
                variant="info"
                :label="transaction.chart_of_account.name"
            />
        </div>

        <div class="border-b border-gray-200 py-6">
            <div class="mb-4" v-if="transaction.exchange_rate">
                <p class="mb-1 text-sm text-gray-500">
                    {{
                        $t(
                            'bookeepingBank.accountTransactions.slideOut.exchangeRate'
                        )
                    }}
                </p>
                <p class="text-sm text-gray-700">
                    {{ transaction.exchange_rate.rate }}
                </p>
            </div>

            <template v-if="transaction.type === 'income'">
                <div class="mb-4" v-if="transaction.chart_of_account_id">
                    <p class="mb-1 text-sm text-gray-500">
                        {{
                            $t(
                                'bookeepingBank.accountTransactions.slideOut.accountName'
                            )
                        }}
                    </p>
                    <p class="text-sm text-gray-700">
                        {{ transaction.chart_of_account.name }}
                    </p>
                </div>

                <div class="mb-4" v-if="transaction.opposite_transaction">
                    <p class="mb-1 text-sm text-gray-500">
                        {{
                            $t(
                                'bookeepingBank.accountTransactions.slideOut.receivedVia'
                            )
                        }}
                    </p>
                    <p class="text-sm text-gray-700">
                        {{
                            transaction.opposite_transaction.chart_of_account
                                .name
                        }}
                    </p>
                </div>

                <div class="mb-4" v-if="transaction?.booked?.contact_id">
                    <p class="mb-1 text-sm text-gray-500">
                        {{
                            $t(
                                'bookeepingBank.accountTransactions.slideOut.customer'
                            )
                        }}
                    </p>
                    <router-link
                        class="text-sm text-primary-600"
                        :to="{
                            name: 'contacts.detail',
                            params: { id: transaction.booked.contact_id },
                        }"
                    >
                        {{ transaction.booked?.contact?.contact_name }}
                    </router-link>
                </div>
            </template>

            <template v-else>
                <div class="mb-4" v-if="transaction?.booked?.contact_id">
                    <p class="mb-1 text-sm text-gray-500">
                        {{
                            $t(
                                'bookeepingBank.accountTransactions.slideOut.supplier'
                            )
                        }}
                    </p>
                    <router-link
                        class="text-sm text-primary-600"
                        :to="{
                            name: 'contacts.detail',
                            params: { id: transaction.booked.contact_id },
                        }"
                    >
                        {{ transaction.booked?.contact?.contact_name }}
                    </router-link>
                </div>
            </template>

            <bookeeping-bank-detail-account-transaction-details-journal-history
                :transaction="transaction"
                expanded
            />
        </div>

        <bookeeping-bank-detail-account-transaction-details-statement
            v-if="transaction.payment_transaction"
            class="flex-1"
            :transaction="transaction"
            :suppress-options="true"
            :suppress-details="true"
        />

        <bookeeping-bank-detail-account-transaction-unreconcile-modal
            :show="showUnreconcileModal"
            @modal-close="showUnreconcileModal = false"
            @modal-confirm="onUnreconcile"
        />

        <transaction-book-close-modal
            :code="code"
            :date="parameters?.date"
            @modal-close="code = null"
        />
    </div>
</template>
<script setup>
import BigNumber from 'bignumber.js'
import { printContent, symbolCurrency } from '@tenant/utils/helper'

const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})

const emit = defineEmits(['unreconciled', 'on-click-edit'])

const taxAmount = computed(() => {
    const bookedItem = props.transaction?.booked
    if (!bookedItem || props.transaction.tax_rule_id) return 0

    if (bookedItem?.line_items?.length === 1) {
        let amount
        if (props.transaction.type === 'income') {
            amount =
                bookedItem.sale_document.amount_gross_total -
                bookedItem.sale_document.amount_net_total
        } else {
            amount = bookedItem.amount_gross_total - bookedItem.amount_net_total
        }

        return BigNumber(amount).decimalPlaces(2).toNumber()
    }

    return 0
})

const taxLabel = computed(() => {
    const bookedItem = props.transaction?.booked
    if (!bookedItem || props.transaction.tax_rule_id) return ''

    if (bookedItem?.line_items?.length === 1) {
        if (props.transaction.type === 'income') {
            const lineItem = bookedItem.sale_document.line_items[0]
            return `${symbolCurrency(
                taxAmount.value,
                bookedItem.sale_document.currency
            )} (${BigNumber(lineItem.tax_rate).toNumber()}%)`
        } else {
            const lineItem = bookedItem.line_items[0]
            return `${symbolCurrency(taxAmount.value, bookedItem.currency)} (${
                lineItem.tax_rule.name
            } - ${BigNumber(lineItem.tax_rule.tax_rate).toNumber()}%)`
        }
    }

    return ''
})

const emitter = useEmitter()
const { errorNotify, successNotify } = useNotification()

const showUnreconcileModal = ref(false)
const {
    execute: executeUnreconcile,
    loading: isReconciling,
    code,
    parameters,
} = useApi('api/booked-transactions/{id}/unreconciled', 'POST')

const onUnreconcile = async () => {
    try {
        showUnreconcileModal.value = false

        emitter.emit('set-loading', true)

        await executeUnreconcile({
            params: {
                id: props.transaction.id,
            },
        })
        showUnreconcileModal.value = false
        emit('unreconciled')

        successNotify({
            text: 'Transaction unreconciled.',
        })
    } catch ({ errorMessage }) {
        errorNotify({
            text: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}
</script>
