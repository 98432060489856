<template>
    <data-grid
        class="-mx-6 mb-6"
        :columns="columns"
        :data-source="transactions"
        :table-borderless="true"
        :border-table-header="true"
    >
        <template #column-date_of_transfer="{ item }">
            {{
                $filters.dateCustomFormat(
                    item.date_of_transfer,
                    DATE_HUMAN_FORMAT,
                    DATE_SERVER_FORMAT,
                    true
                )
            }}
        </template>

        <template #column-spent="{ item }">
            <template
                v-if="
                    validTransactionType(item.transaction_type, OUTCOME_FIELDS)
                "
            >
                <template v-if="item.transaction_type">
                    {{ $filters.numberFormat(Math.abs(item.amount)) }}
                </template>
                <template v-else-if="item.amount < 0">
                    {{ $filters.numberFormat(Math.abs(item.amount)) }}
                </template>
            </template>
        </template>

        <template #column-received="{ item }">
            <template
                v-if="
                    validTransactionType(item.transaction_type, INCOME_FIELDS)
                "
            >
                <template v-if="item.transaction_type">
                    {{ $filters.numberFormat(Math.abs(item.amount)) }}
                </template>
                <template v-else-if="item.amount >= 0">
                    {{ $filters.numberFormat(Math.abs(item.amount)) }}
                </template>
            </template>
        </template>
    </data-grid>
</template>
<script setup>
import {
    INCOME_FIELDS,
    OUTCOME_FIELDS,
} from '@tenant/modules/tenant/bookeeping-bank/utils/constants'
import { useAssignTransactions } from '@tenant/modules/tenant/bookeeping-bank/compositions/use-import-transactions'
import { DATE_HUMAN_FORMAT, DATE_SERVER_FORMAT } from '@tenant/utils/constants'

defineProps({
    transactions: {
        type: Array,
        default: () => [],
    },
})

const { t } = useI18n()
const { validTransactionType } = useAssignTransactions()

const columns = [
    {
        property: 'date_of_transfer',
        label: t('bookeepingBank.import.transactionsGrid.date'),
    },
    {
        property: 'payee',
        label: t('bookeepingBank.import.transactionsGrid.customerName'),
    },
    {
        property: 'reference',
        label: t('bookeepingBank.import.transactionsGrid.reference'),
    },
    {
        property: 'spent',
        label: t('bookeepingBank.import.transactionsGrid.spent'),
    },
    {
        property: 'received',
        label: t('bookeepingBank.import.transactionsGrid.received'),
    },
]
</script>
