<template>
    <div
        class="grid grid-cols-3 items-start gap-6 border-b border-gray-200 p-6"
    >
        <div class="flex flex-1 flex-col gap-1 text-sm text-gray-900">
            <p class="font-semibold">
                {{ $t('bookeepingBank.import.columnHeadingFrom') }}
            </p>
            <div
                class="flex h-full items-center gap-2.5 rounded-md bg-gray-100 p-2.5 font-normal"
            >
                <base-icon name="general:rows-01" variant="inherit" />
                <template v-if="skipHeading">
                    {{ currentColumnHeading[columnNumber - 1] }}
                </template>
                <template v-else>
                    {{
                        $t('bookeepingBank.import.column', {
                            number: columnNumber,
                        })
                    }}
                </template>
            </div>
        </div>

        <div class="flex flex-1 flex-col gap-1 text-sm text-gray-900">
            <p class="font-semibold">
                {{ $t('bookeepingBank.import.previewFrom') }}
            </p>
            <div
                class="flex h-full items-center gap-2.5 rounded-md bg-gray-100 p-2.5 font-normal"
            >
                <base-icon name="line-icons:general:eye" variant="inherit" />
                {{ currentHeadingPreview[columnNumber - 1] }}
            </div>
        </div>

        <div class="flex flex-1 flex-col gap-1 text-sm text-gray-900">
            <p class="font-semibold">
                {{ $t('bookeepingBank.import.assignToField') }}
            </p>
            <form-single-select
                v-model="selectAssignValue"
                size="sm"
                :options="fields"
                :error-message="errorAssignColumn"
            >
                <template #option-icon="{ selected, item }">
                    <div
                        v-if="selected || !!assignColumns[item.value]"
                        class="align-self-end pointer-events-none inset-y-0 right-0 flex items-center"
                    >
                        <base-icon
                            name="line-icons:general:check"
                            variant="primary"
                            size="md"
                        />
                    </div>
                </template>
            </form-single-select>
        </div>
    </div>
</template>
<script setup>
import { IMPORT_FIELDS } from '@tenant/modules/tenant/bookeeping-bank/utils/constants'
import { useAssignTransactions } from '@tenant/modules/tenant/bookeeping-bank/compositions/use-import-transactions'

const props = defineProps({
    columnNumber: {
        type: Number,
    },
    page: {
        type: Number,
    },
})

const { t } = useI18n()

const { getTransaction } = useAssignTransactions()

const fileData = useFieldValue('file_data')
const skipHeading = useFieldValue('skip_heading')
const { value: assignColumns, setValue: setAssignColumns } =
    useField('assign_columns')

const fields = Object.entries(IMPORT_FIELDS).map(([key, value]) => ({
    label: t(`bookeepingBank.import.fields.${key}`),
    value: value,
}))

const selectAssignValue = computed({
    get: () => {
        const found = Object.values(assignColumns.value || {}).find(
            (i) => i.column === props.columnNumber
        )

        if (!found) {
            return ''
        }

        return found.value
    },
    set: (value) => {
        const dataAssign = assignColumns.value || {}
        if (value in dataAssign) {
            delete dataAssign[value]
        }

        if (selectAssignValue.value in dataAssign) {
            delete dataAssign[selectAssignValue.value]
        }

        if (!value) {
            return
        }

        setAssignColumns({
            ...dataAssign,
            [value]: {
                column: props.columnNumber,
                value: value,
            },
        })
    },
})

const errorAssignColumn = computed(() => {
    const transaction = getTransaction(props.page - 1)

    if (
        transaction.success ||
        !selectAssignValue.value ||
        !transaction.errors
    ) {
        return null
    }

    return transaction.errors[selectAssignValue.value] || null
})

const withColumnHeading = computed(() => {
    if (skipHeading.value) {
        return 1
    }

    return 0
})

const currentColumnHeading = computed(() => {
    if (!fileData.value) {
        return []
    }

    return fileData.value[withColumnHeading.value - 1]
})

const currentHeadingPreview = computed(() => {
    const { page } = props
    if (!fileData.value) {
        return []
    }

    return fileData.value[page + withColumnHeading.value - 1]
})
</script>
