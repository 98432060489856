<template>
    <data-grid :columns="columns" table-borderless>
        <template #column-contact="{ item: { value, key } }">
            <form-field-array-error
                :name="`line_items[${key}].contact_id`"
                #="{ errorMessage }"
            >
                <form-contact-select
                    v-model="value.contact_id"
                    :input-display="getContactName(value.contact)"
                    @on-contact-changed="onContactChange($event, value)"
                    :error-message="errorMessage"
                />
            </form-field-array-error>
        </template>

        <template #column-description="{ item: { value, key } }">
            <form-field-array-error
                :name="`line_items[${key}].notes`"
                #="{ errorMessage }"
            >
                <form-text-input
                    v-model="value.notes"
                    size="sm"
                    :error-message="errorMessage"
                />
            </form-field-array-error>
        </template>

        <template #column-account="{ item: { value, key } }">
            <form-field-array-error
                :name="`line_items[${key}].chart_of_account_id`"
                #="{ errorMessage }"
            >
                <form-detail-chart-of-account
                    v-model="value.chart_of_account_id"
                    :include-modal="false"
                    :can-create="false"
                    :input-display="
                        getChartOfAccountName(value.chart_of_account_id)
                    "
                    :error-message="errorMessage"
                />
            </form-field-array-error>
        </template>

        <template #column-tax_rate="{ item: { value, key } }">
            <form-field-array-error
                :name="`line_items[${key}].tax_rule_id`"
                #="{ errorMessage }"
            >
                <form-single-select
                    v-model="value.tax_rule_id"
                    :options="taxRules"
                    option-value="id"
                    :option-label="customTaxLabel"
                    size="sm"
                    :error-message="errorMessage"
                />
            </form-field-array-error>
        </template>

        <template #column-amount="{ item: { value, key } }">
            <form-field-array-error
                :name="`line_items[${key}].amount`"
                #="{ errorMessage }"
            >
                <form-number-input
                    v-model="value.amount"
                    @update:modelValue="$emit('amount-change', $event)"
                    size="sm"
                    :precision="2"
                    :error-message="errorMessage"
                />
            </form-field-array-error>
        </template>

        <template #column-action>
            <base-icon
                class="cursor-pointer"
                name="line-icons:general:trash-01"
                variant="gray"
                @click="$emit('remove')"
            />
        </template>
    </data-grid>
</template>

<script setup>
import { getChartOfAccountName, getContactName } from '@tenant/utils/helper'

const { t } = useI18n()
defineEmits(['remove', 'amount-change'])

const columns = [
    {
        property: 'contact',
        label: t('bookeepingBank.matchTransactions.bankFeeColumns.contact'),
    },
    {
        property: 'description',
        label: t('bookeepingBank.matchTransactions.bankFeeColumns.description'),
    },
    {
        property: 'account',
        label: t('bookeepingBank.matchTransactions.bankFeeColumns.account'),
    },
    {
        property: 'tax_rate',
        label: t('bookeepingBank.matchTransactions.bankFeeColumns.taxRate'),
    },
    {
        property: 'amount',
        label: t('bookeepingBank.matchTransactions.bankFeeColumns.amount'),
    },
]

const onContactChange = ({ id, item }, value) => {
    value.contact_id = id
    value.contact = item
}

const { taxRules } = useTaxRules()
const customTaxLabel = (option) => `${option.name} (${option.tax_rate})`
</script>
