<template>
    <base-accordion
        class="rounded-md border border-gray-200"
        :icon-bordered="false"
        :trigger-classes="triggerClasses"
        @toggle="onToggle"
    >
        <template #title>
            <p class="mr-1 text-xs">
                {{ $t('journalHistory.title') }}
            </p>
            <base-badge
                variant="info"
                :label="
                    transaction?.journal_lines?.length > 0
                        ? transaction?.journal_lines?.length
                        : transaction?.booked?.journal_entry?.journal_lines
                              ?.length ?? 0
                "
            />
        </template>

        <template #icon="{ isOpen }">
            <base-icon
                v-if="isOpen"
                name="line-icons:arrows:chevron-down"
                size="sm"
                variant="inherit"
            />
            <base-icon
                v-else
                name="line-icons:arrows:chevron-right"
                size="sm"
                variant="inherit"
            />
        </template>

        <template #content>
            <base-currency class="px-4" />
            <data-grid
                :columns="columns"
                :data-source="
                    transaction?.journal_lines?.length > 0
                        ? transaction?.journal_lines
                        : transaction?.booked?.journal_entry?.journal_lines
                "
                :summary-data="summaryData"
                class="mt-2 rounded-none border-x-0 border-b-0"
            >
                <template #column-account="{ item }">
                    {{ item?.chart_of_account?.name }}
                </template>

                <template #column-debit="{ item }">
                    <span
                        :class="{
                            'font-bold text-gray-700': item.isSummary,
                        }"
                        >{{ $filters.numberFormat(item?.debit) }}
                    </span>
                </template>

                <template #column-credit="{ item }">
                    <span
                        :class="{
                            'font-bold text-gray-700': item.isSummary,
                        }"
                        >{{ $filters.numberFormat(item?.credit) }}
                    </span>
                </template>
            </data-grid>
        </template>
    </base-accordion>
</template>

<script setup>
const props = defineProps({
    transaction: {
        type: Object,
        required: true,
    },
})

const { t } = useI18n()

const columns = [
    {
        property: 'account',
        label: t('journalHistory.account'),
    },
    {
        property: 'debit',
        label: t('general.debit'),
    },
    {
        property: 'credit',
        label: t('general.credit'),
    },
]

const triggerClasses = ref('p-4')
const onToggle = (isOpen) => {
    triggerClasses.value = isOpen ? 'p-4 pb-0' : 'p-4'
}

const summaryData = computed(() => {
    const transactions =
        props.transaction?.journal_lines?.length > 0
            ? props.transaction?.journal_lines
            : props.transaction?.booked?.journal_entry?.journal_lines

    if (!transactions) {
        return []
    }

    const summary = {
        debit: 0,
        credit: 0,
    }

    transactions.forEach((item) => {
        summary.debit += Number(item.debit)
        summary.credit += Number(item.credit)
    })

    return [
        {
            debit: summary.debit,
            credit: summary.credit,
        },
    ]
})
</script>
