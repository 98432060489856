<template>
    <slide-out-menu
        size="auto"
        :has-padding="false"
        :has-separator="false"
        class="w-[558px]"
        @close="onClose"
    >
        <template #close-icon>
            <div class="pr-6 pt-6">
                <base-icon
                    size="lg"
                    variant="gray"
                    class="cursor-pointer"
                    name="line-icons:general:x"
                    @click.prevent="onClose"
                />
            </div>
        </template>

        <template #slide-out-menu-header>
            <div class="pl-6 pt-6">
                <div class="text-xl text-gray-900">{{ title }}</div>
            </div>
        </template>

        <template v-if="transaction">
            <bookeeping-bank-detail-account-transaction-edit
                v-if="state.showEdit"
                :transaction="transaction"
                @on-cancel="onToggleShowEdit"
            />

            <div v-show="!state.showEdit">
                <div class="border-b border-gray-200">
                    <base-content-tabs
                        variant="horizontal"
                        :items="tabs"
                        :has-margin="false"
                        tab-style="rounded"
                        default="transactions"
                        class="mb-4 px-6"
                        @change-tab="onChangeTab"
                    />
                </div>

                <bookeeping-bank-detail-account-transaction-details-tab
                    v-show="activeTabName === 'transactions'"
                    :transaction="transaction"
                    @unreconciled="onUnreconciled"
                    @on-click-edit="onToggleShowEdit"
                />
                <bookeeping-bank-detail-account-transaction-comments-history-tab
                    v-show="activeTabName === 'comments-history'"
                    :transaction="transaction"
                />
            </div>
        </template>
    </slide-out-menu>
</template>

<script setup>
const props = defineProps({
    transaction: {
        type: [Object, null],
        required: true,
    },
})
const emit = defineEmits(['unreconciled', 'close'])

const { t } = useI18n()

const isOutCome = computed(() => props.transaction.type === 'outcome')

const title = computed(() => {
    if (state.showEdit) {
        return isOutCome.value
            ? t('bookeepingBank.accountTransactions.slideOut.expense')
            : t('bookeepingBank.accountTransactions.slideOut.income')
    }

    return isOutCome.value
        ? t('bookeepingBank.accountTransactions.slideOut.spendTitle')
        : t('bookeepingBank.accountTransactions.slideOut.receiveTitle')
})

const tabs = computed(() => [
    {
        label: t(
            'bookeepingBank.accountTransactions.slideOut.transactionDetailsTab'
        ),
        name: 'transactions',
    },
    {
        label: t(
            'bookeepingBank.accountTransactions.slideOut.commentsHistoryTab'
        ),
        name: 'comments-history',
    },
])

const activeTabName = ref('transactions')
const state = reactive({
    showEdit: false,
})

const onChangeTab = ({ name }) => {
    activeTabName.value = name
}

const onToggleShowEdit = () => {
    state.showEdit = !state.showEdit
}

const onUnreconciled = () => {
    emit('unreconciled')
}

const onClose = () => {
    state.showEdit = false
    emit('close')
}
</script>
